const { HIDDEN_COMPONENTS, QUERY_PARAMS } = require('../../constants/app');
const { DEVICE_TYPE } = require('../../constants/commons');

const allowDuplicatedPayment = (duplicatedPayment) => {
  const currentDate = new Date();
  const firstAppearanceDuplicatedModal = duplicatedPayment?.firstAppearance;
  const fiveMinutesInMilliseconds = 300_000;
  const passedFiveMinutes = firstAppearanceDuplicatedModal
    ? currentDate - firstAppearanceDuplicatedModal > fiveMinutesInMilliseconds
    : false;

  return duplicatedPayment?.allowDuplicatedPayment || passedFiveMinutes;
};

const getDataFromUrl = (url) => {
  const searchParams = new URLSearchParams(url);

  return {
    [QUERY_PARAMS.CHECKOUT_SESSION_ID]: searchParams.get(QUERY_PARAMS.CHECKOUT_SESSION_ID),
    [QUERY_PARAMS.ALLOW_DUPLICATED_PAYMENT]: searchParams.get(QUERY_PARAMS.ALLOW_DUPLICATED_PAYMENT),
  };
};

const payload = ({ currentState, payload, urlParams }) => {
  const urlData = getDataFromUrl(urlParams);

  const checkoutSessionId = currentState.page?.data?.checkout?.session?.session_id || urlData[QUERY_PARAMS.CHECKOUT_SESSION_ID];
  if (checkoutSessionId) {
    payload.checkout_session_id = checkoutSessionId;
  }

  if (allowDuplicatedPayment(currentState.duplicatedPayment) || urlData[QUERY_PARAMS.ALLOW_DUPLICATED_PAYMENT]) {
    payload.allow_duplicated_payment = true;
  }

  return payload;
};

const handleModalAppearance = ({ currentState, hiddenComponents, isSameStep, dispatch, showDuplicatedPaymentModal, urlParams }) => {
  const isMobile = currentState.configurations.deviceType === DEVICE_TYPE.MOBILE;

  if (!isMobile) {
    return;
  }

  const duplicatedPayment = hiddenComponents?.find(
    (component) => component.id === HIDDEN_COMPONENTS.DUPLICATED_PAYMENT,
  );

  if (!duplicatedPayment) {
    return;
  }

  const urlData = getDataFromUrl(urlParams);

  const shouldShowDuplicatedPaymentModal =
    allowDuplicatedPayment(currentState.duplicatedPayment) === false &&
    urlData[QUERY_PARAMS.ALLOW_DUPLICATED_PAYMENT] === null &&
    currentState.duplicatedPayment?.show === false &&
    isSameStep;

  if (!shouldShowDuplicatedPaymentModal) {
    return;
  }

  const paidWithALoggedUser = duplicatedPayment.value.value;
  dispatch(showDuplicatedPaymentModal({ paidWithALoggedUser }));
};

const addCheckoutSessionToUrl = ({ url, checkoutSessionId, duplicatedPaymentState = {} }) => {
  if (!checkoutSessionId || !url) {
    return url;
  }

  const [baseUrl, queryString] = url.split('?');

  const searchParams = new URLSearchParams(queryString);
  searchParams.append(QUERY_PARAMS.CHECKOUT_SESSION_ID, checkoutSessionId);

  if (allowDuplicatedPayment(duplicatedPaymentState)) {
    searchParams.append(QUERY_PARAMS.ALLOW_DUPLICATED_PAYMENT, 'true');
  }

  return `${baseUrl}?${searchParams.toString()}`;
}

module.exports = {
  payload,
  handleModalAppearance,
  addCheckoutSessionToUrl,
};
