module.exports = (i18n) => ({
  ADDRESS_DETAILS: i18n.gettext('Datos de tu dirección'),
  BANK: i18n.gettext('Banco'),
  CANCEL: i18n.gettext('Cancelar'),
  CHOOSE_LOCATION: i18n.gettext('Elige tu ubicación'),
  CLICK_BOX: i18n.gettext('Haz clic en el recuadro para continuar.'),
  CONDITIONS: i18n.gettext('Condiciones'),
  CONTINUE: i18n.gettext('Continuar'),
  COUNTDOWN_MESSAGE: (val1, val2) => i18n.gettext('En {0} segundos te llevaremos a {1}.', val1, val2),
  ENTER: i18n.gettext('Ingresar'),
  ENTER_VALID_CITY: i18n.gettext('Ingresa una ciudad válida'),
  IM_NOT: i18n.gettext('No soy'),
  INCOMPLETE_CARD_NUMBER: i18n.gettext('Ingresa el número de la tarjeta.'),
  INCOMPLETE_CVV: i18n.gettext('Ingresa el código de seguridad.'),
  INCOMPLETE_EXP_DATE: i18n.gettext('Ingresa la fecha de vencimiento.'),
  INVALID_CAPTCHA: i18n.gettext('El captcha es inválido. Complétalo de nuevo por favor.'),
  INVALID_CARD_NUMBER: i18n.gettext('Revisa el número digitado.'),
  INVALID_CVV: i18n.gettext('Revisa el código digitado.'),
  INVALID_EXP_DATE: i18n.gettext('Ingresa una fecha válida.'),
  INVALID_FIELD: i18n.gettext('No es posible continuar el pago con esta tarjeta.'),
  INVALID_PAYMENT_METHOD: i18n.gettext('No es posible continuar el pago con esta tarjeta.'),
  MP: i18n.gettext('Mercado Pago'),
  MY_CODE: i18n.gettext('No sé mi código'),
  NOT_APPLY_INSTALLMENTS: i18n.gettext('No aplican cuotas'),
  NOT_IDENTIFY_ZIPCODE: i18n.gettext('No encontramos el código postal. Intenta con otros datos.'),
  OF_MP: i18n.gettext('de Mercado Pago.'),
  ACCEPT_THE_TERMS: i18n.gettext('Al pagar, afirmo que soy mayor de edad y acepto los'),
  PANEL: i18n.gettext('tu panel de mercado pago'),
  PERCENT_OF_TOTAL: (val) => i18n.gettext('{0}% del total', val),
  PRIVACY: i18n.gettext('Privacidad'),
  PROCESSED_BY: i18n.gettext('Procesado por'),
  PROTECTED_BY_RECAPTCHA: i18n.gettext('Protegido por reCAPTCHA'), // TODO: TALK TO UX ABOUT THE '-' REMOVED FROM THE KEY
  REQUIRED_FIELD: i18n.gettext('Llena este dato.'),
  SIGN_OUT: i18n.gettext('Algo salió mal. Cierra sesión desde'),
  TERMS_AND_CONDITIONS: i18n.gettext('Términos y condiciones'),
  TERMS_CONDITIONS: i18n.gettext('Términos y condiciones'),
  GENERAL_TERMS: i18n.gettext('Términos generales'),
  PARTICULAR_CONDITIONS: i18n.gettext('Condiciones Particulares'),
  TITLE: i18n.gettext('Mercado Pago - Checkout'),
  TRY_AGAIN: i18n.gettext('e intenta nuevamente.'),
  TRY_AMOUNT_LOWER_THAN_BALANCE: i18n.gettext('El monto excede tu saldo disponible.'),
  TRY_AMOUNT_LOWER_THAN_LIMIT: i18n.gettext('El monto excede tu límite disponible.'),
  TRY_AMOUNT_LOWER_THAN_TOTAL_AMOUNT: i18n.gettext('Para usar 2 medios de pago, ingresa un monto menor.'),
  TRY_BIGGER_AMOUNT: i18n.gettext('Intenta de nuevo con un monto mayor.'),
  TRY_LOWER_AMOUNT: i18n.gettext('Intenta de nuevo con un monto menor.'),
  USING_FULL_BALANCE: i18n.gettext('Estás usando todo tu saldo.'),
  YOUR_ACCOUNT: i18n.gettext('Estás pagando con tu cuenta de Mercado Pago.'),
  LOG_IN: i18n.gettext('Iniciar sesión'),
  PAY_WITH_MY_ACCOUNT: i18n.gettext('Pagar con mi cuenta'),
  CONTINUE_AS_A_GUEST: i18n.gettext('Continuar como invitado'),
  IF_YOUR_PACKAGE_DOES_NOT_ARRIVE_WE_WILL_REFUND_YOUR_MONEY: 'Si tu paquete no llega, te devolvemos el dinero.',
  PROTECTED_PURCHASE: i18n.gettext('Compra Protegida'),
  YOU_WILL_BE_ABLE_TO_SEE_THIS_INFORMATION_AFTER_SELECTING_THE_NUMBER_OF_INSTALLMENTS: i18n.gettext(
      'Podrás ver esta información luego de seleccionar la cantidad de cuotas'
  ),
  OH_NO_SOMETHING_WENT_WRONG: i18n.gettext('Oh, no, algo anduvo mal.'),
  MERCADO_LIBRE: i18n.gettext('Mercado Libre'),
  MERCADO_PAGO: i18n.gettext('Mercado Pago'),
  THE_4_DIGITS_ON_THE_FRONT_OF_THE_CARD: i18n.gettext('Los 4 dígitos al frente de la tarjeta'),
  LAST_3_DIGITS_ON_THE_BACK_OF_THE_CARD: i18n.gettext('Últimos 3 dígitos al dorso de la tarjeta'),
  ENTER_AN_AMOUNT_FROM: (val) => i18n.gettext('Ingresa un monto desde {0}.', val),
  HELLO: i18n.gettext('Hola'),
  CODE: i18n.gettext('Código'),
  IT_WAS_NOT_POSSIBLE_TO_COPY_THE_CODE_PLEASE_TRY_AGAIN: i18n.gettext('No fue posible copiar el código. Por favor, intenta nuevamente.'),
  READY_CODE_COPIED: i18n.gettext('¡Listo! Código copiado.'),
  YOUR_PAYMENT_IS_BEING_PROCESSED_BY_THE_OTHER_INSTITUTION: i18n.gettext('Su pago está siendo procesado por la otra institución.'),
  THIS_WILL_TAKE_LESS_THAN_30_SECONDS: i18n.gettext('Esto tomará menos de 30 segundos.'),
  BY_CONTINUING_YOU_ACCEPT_THE: i18n.gettext('Al continuar, acepta los '),
  MORE_SAVED_CARDS: i18n.gettext('Más tarjetas guardadas'),
  SHOW_MORE_SAVED_CARDS: i18n.gettext('Mostrar más tarjetas guardadas'),
  HOW_TO_USE_IT: i18n.gettext('Cómo usarlo'),
  CANCEL_PAYMENT: i18n.gettext('Cancelar pago'),
  ONE_MOMENT: i18n.gettext('¡Un momento!'),
  IF_YOU_CLOSE_THIS_WINDOW_YOU_WILL_LOSE_ALL_THE_DATA_YOU_LOADED: i18n.gettext('Si cierras esta ventana perderás todos los datos que cargaste.'),
  CONTINUE_PAYMENT: i18n.gettext('Continuar pago'),
  CLOSE_AND_CANCEL_PAYMENT: i18n.gettext('Cerrar y cancelar pago'),
  WE_ARE_VALIDATING_THE_PAYMENT_WITH_THE_BANK: i18n.gettext('Estamos validando el pago con el banco'),
  EXPIRED_CODE_PLEASE_GENERATE_ANOTHER: i18n.gettext('Código expirado. Por favor genera otro.'),
  GENERATE_A_NEW_CODE: i18n.gettext('Generar un nuevo código'),
  YOU_HAVE_X_MINUTES_TO_SCAN_IT: (val) => i18n.gettext('Tienes {0} minutos para escanearlo', val),
  DO_NOT_CLOSE_THIS_SCREEN_UNTIL_YOU_FINISH_THE_PAYMENT: i18n.gettext('No cierres esta pantalla hasta terminar el pago'),
  YOU_NEED_A_PERMIT_TO_MAKE_THE_PAYMENT: i18n.gettext('Necesitas un permiso para hacer el pago'),
  IF_YOU_NEED_THIS_ACCESS_ASK_THE_ACCOUNT_ADMINISTRATOR: i18n.gettext('Si precisás este acceso, pedíselo al administrador de la cuenta.'),
  RETURN_TO_SITE: i18n.gettext('Volver al sitio'),
  WAIT_MOMENT_AND_YOU_WILL_BE_REDIRECTED: i18n.gettext('Aguarda un momento y serás redirigido'),
  WAIT_MOMENT_AND_YOU_WILL_BE_REDIRECTED_TO: (val) => i18n.gettext('Aguarda un momento y serás redirigido a {0}', val),
  SOMETHING_WENT_WRONG: i18n.gettext('Algo salió mal...'),
  SORRY_PLEASE_TRY_AGAIN: i18n.gettext('Lo sentimos, vuelve a intentarlo.'),
  MERCADOPAGO: i18n.gettext('MercadoPago'),
  YOU_WILL_CONTINUE_THE_PAYMENT_FROM_OTHER_TAB: i18n.gettext('Vas a continuar el pago desde otra pestaña'),
  MESSAGE_FOR_THIS_TIME_IT_IS_NOT_POSSIBLE_TO_COMPLETE_YOUR_PAYMENT: (val) => i18n.gettext('Por esta vez no es posible terminar tu pago con {0}, te recomendamos pagar con otro medio. Recuerda que a futuro necesitarás activar las ventanas emergentes en tu navegador.', val),
  PAY_WITH_ANOTHER: i18n.gettext('Pagar con otro medio'),
  OH_NO_YOUR_BROWSER_IS_NOT_SECURE_TRY_ANOTHER_ONE: i18n.gettext('Oh, no, tu navegador no es seguro, intenta con otro.'),
  RETRY: i18n.gettext('Reintentar'),
  WHAT_YOU_WANTED_TO_PAY_IS_NO_LONGER_AVAILABLE: i18n.gettext('Lo que querías pagar ya no se encuentra disponible'),
  IT_WAS_AVAILABLE_UNTIL_X_HOURS: (val) => i18n.gettext('Estuvo disponible hasta el {0} hs.', val),
  LESS_AND_LESS_IS_MISSING: i18n.gettext('¡Cada vez falta menos!'),
  THE_PURCHASE_YOU_WANT_WILL_BE_AVAILABLE_FROM: (val) => i18n.gettext('La compra que quieres estará disponible desde el {0}', val),
  THE_PURCHASE_YOU_WANT_IS_NOT_YET_AVAILABLE_TRY_AGAIN_LATER: i18n.gettext('La compra que quieres aún no está disponible. Vuelve a intentarlo más tarde.'),
  RETURN_TO: (val) => i18n.gettext('Volver a {0}', val),
  THE_PAYMENT_FLOW_EXPIRED: i18n.gettext('El flujo de pago expiro.'),
  RESTART_THE_FLOW: i18n.gettext('Reiniciar el flujo'),
  AGREE_ON_PAYMENT_WITH_THE_SELLER: i18n.gettext('Acuerda el pago con el vendedor'),
  AT_THE_MOMENT_ONLINE_PAYMENT_OPTIONS_ARE_DISABLED_CONTACT_THE_SELLER_TO_COMPLETE_YOUR_PURCHASE: i18n.gettext('Por el momento, las opciones de pago online están desactivadas. Contáctate con el vendedor para terminar tu compra.'),
  CLOSE: i18n.gettext('Cerrar'),
  COMPLETE_THE_PAYMENT: i18n.gettext('Completar pago'),
  DO_NOT_CLOSE_THIS_MESSAGE_UNTIL_PAYMENT_IS_COMPLETED: i18n.gettext('No cierres este mensaje hasta completar el pago'),
  YOU_COMPLETING_PAYMENT_FROM_ANOTHER_TAB_BUT_IF_YOU_CLOSE_MESSAGE_NOW_WILL_BE_CANCELLED: i18n.gettext('Estás completando el pago desde otra pestaña. Pero si cierras este mensaje ahora, se cancelará el pago.'),
  PROCESSING: i18n.gettext('Procesando'),
  PROCESSING_THE_PAYMENT: i18n.gettext('Procesando el pago'),
  HOW_DO_YOU_WANT_TO_PAY: i18n.gettext('¿Cómo quieres pagar?'),
  BROWSER_NOT_SUPPORTED: i18n.gettext('Navegador no soportado'),
  ACCESS_DENIED: i18n.gettext('Access Denied'),
  PAYMENT_NOT_AVAILABLE: i18n.gettext('Pago no disponible'),
  BLOCKED_POP_UPS: i18n.gettext('Pop-ups bloqueados'),
  REDIRECT_NOTICE: i18n.gettext('Aviso de redirección'),
  AN_ERROR_HAS_OCCURRED: i18n.gettext('Ha ocurrido un error'),
  PAY_WITH_MERCADO_PAGO: i18n.gettext('Paga con Mercado Pago'),
  PAYMENT_LINK: i18n.gettext('Link de pago'),
  ENTER_THE_LINK_CHOOSE_HOW_TO_PAY: i18n.gettext('Ingresa al link, elige cómo pagar, ¡y listo!'),
  PAY_YOUR_WITH_MERCADO_PAGO: ((val1, val2) => i18n.gettext('Paga tu {0} con Mercado Pago: {1}', val1, val2)),
  THIS_PAGE_IS_NOT_AVAILABLE_AT_THE_MOMENT: i18n.gettext('Esta página no está disponible por el momento'),
  WE_ARE_WORKING_TO_RESOLVE_A_TECHNICAL_ISSUE: i18n.gettext('Estamos trabajando para resolver un problema técnico. Te recomendamos volver a la página anterior.',),
  WEBVIEW_INTEGRATION_IS_NO_LONGER_AVAILABLE: i18n.gettext('La integración WebView ya no está disponible'),
  WE_RECOMMEND_YOU_CHOOSE_ANOTHER_TECHNOLOGY: i18n.gettext('Para mejorar el rendimiento y la calidad, es necesario que cambies la integración mobile a Chrome Custom Tabs (Android) o Safari View Controller (iOS).'),
  INTEGRATE_WITH_OTHER_TECHNOLOGY: i18n.gettext('Cambiar integración mobile'),
  I_NEED_HELP: i18n.gettext('Necesito ayuda'),
  ACCOUNT_OPTION: (val1) => i18n.gettext('Opciones de la cuenta, Estado: {0}', val1),
  ONE_MOMENT_PLEASE: i18n.gettext('Un momento por favor'),
  REDIRECTING_TO_COMPLETE_PAYMENT: i18n.gettext('Te estamos redirigiendo para completar tu pago'),
  LOGO_MP: i18n.gettext("Logo de Mercado Pago"),
  REVIEW_YOUR_PURCHASE: i18n.gettext('Revisá tu compra'),
  YOU_ENTERED_AS: i18n.gettext('Ingresaste como:'),
  CHANGE_ACCOUNT: i18n.gettext('Cambiar de cuenta'),
  YOU_ARE_LOGGED_AS: i18n.gettext('Iniciaste sesión con la cuenta:'),
  LOG_OUT: i18n.gettext('Cerrar sesión'),
  LOGO_ML: i18n.gettext("Logo Mercado Livre, imagem"),
  DEFERRED_CAPTURE_UNSUPPORTED: i18n.gettext('Esta tarjeta no puede ser usada para pagos con dos tarjetas. Elige otra para continuar.'),
  ENTER_A_DIFFERENT_CARD: i18n.gettext('Ingresa una tarjeta distinta.'),
  CONTEXT: 'mp-cow',
  CHOOSE_OPTION: 'Elige',
  ELIGE: i18n.gettext('Elige'),
  // Currently the context is used in MLB for credits cases and selection of interest installments.
  NO_INTEREST: (context) => context ? i18n.pgettext('mp-cow', 'Sin interés') : i18n.gettext('Sin interés'),
  REMAINING_AMOUNT: i18n.gettext('Monto restante:'),
  AMOUNT_TO_PAY_WITH_THIS_MEANS: i18n.gettext('Monto a pagar con este medio'),
  SHOULD_SELECT_FIRST_PAYMENT_METHOD: i18n.gettext('Elige el primer medio de pago que vas a combinar.'),
  SHOULD_SELECT_SECOND_PAYMENT_METHOD: i18n.gettext('Elige el segundo medio de pago que vas a combinar.'),
  I_UNDERSTAND: i18n.gettext('Entendido'),
  LIMIT_INSUFFICIENT: i18n.gettext('Tu límite disponible no alcanza'),
  CAN_ACTIVATE_COMBINATIONS: i18n.gettext('Activa la opción "Combinar 2 medios de pago" para utilizarlo junto a otro medio.'),
  COMBINE_PAYMENT_METHODS: i18n.gettext('Combinar 2 medios de pago'),
  AMOUNT: i18n.gettext('Monto'),
  MODIFY_FIRST_AMOUNT: i18n.gettext('Modificar el primer monto'),
  TO_MODIFY_REMAINING_AMOUNT: i18n.gettext('Para modificar el monto restante, debes modificar el primer monto.'),
  CHECK_THAT_THE_DATA_IS_EQUAL_TO_YOUR_CARD: i18n.gettext('Revisa que los datos sean iguales a los de tu tarjeta. Si el error continúa, comunícate con tu banco.'),
  PAYMENT_CANNOT_BE_COMPLETED_WITH_THIS_CARD: i18n.gettext('El pago no se puede completar con esta tarjeta, intenta con otra.'),
  AVAILABLE_MONEY_NOT_ENOUGH: i18n.gettext('Tu dinero disponible no alcanza'),
  THERE_IS_A_PRICE_DIFFERENCE_DEPENDING_ON_THE_NUMBER_OF_INSTALLMENTS_YOU_CHOOSE: i18n.gettext('Es una diferenciación en el precio según el número de cuotas que elegiste.'),
  IT_IS_A_DIFFERENTIATION_IN_PRICE_ACCORDING_TO_THE_NUMBER_OF_INSTALLMENTS_YOU_WILL_PAY_BY_CARD: i18n.gettext('Es una diferenciación en el precio según el número de cuotas que pagarás con tarjeta.'),
  DEFINED_ACCORDING_TO_THE_AMOUNT_YOU_CHOSE_TO_PAY_WITH_THE_FIRST_MEANS: i18n.gettext('Definido según el monto que elegiste pagar con el primer medio.'),
  UNA_CUOTA_SIN_INTERES: i18n.gettext('1 cuota sin interés'),
  INVALID_EMAIL: i18n.gettext('Ingresa un e-mail válido.'),
  SECURE_PAYMENT: i18n.gettext('Pago seguro'),
  MODIFY: i18n.gettext('Modificar'),
  I_HAVE_A_DISCOUNT: i18n.gettext('Tengo un descuento'),
  APPLY_DISCOUNT: i18n.gettext('Aplicar descuento'),
  DISCOUNT_CODE: i18n.gettext('Código de descuento'),
  YOU_CAN_ONLY_USE_ONE_DISCOUNT_CODE_AT_A_TIME: i18n.gettext('Solo podés usar un código de descuento por vez'),
  AM_PAYMENT_METHOD_FALLBACK: i18n.gettext('Usar mi dinero disponible en Mercado Pago cuando no sea posible cobrar de la tarjeta'),
  RECEIVE_PAYMENT_DETAILS: i18n.gettext('Recibe el detalle de pago'),
  EMAIL: i18n.gettext('E-mail'),
  SEND_ONLY_PAYMENT_INFO_FOR_AVAILABILITY: i18n.gettext('Solo te enviaremos información del pago para que la tengas disponible.'),
  CAN_NOT_FIND_THE_PAGE: i18n.gettext('No encontramos la página'),
  DESCRIPTION_CAN_NOT_FIND_THE_PAGE_ERROR: i18n.gettext('Si el problema continúa, es posible que la página esté mal configurada.'),
  // MIGRATION KEYs
  PIX_BPP: i18n.gettext('Pagamentos com este meio não são cobertos pela Compra Garantida'),
  CLABE_MESSAGE: i18n.gettext('Al continuar, recibirás las instrucciones para hacer la transferencia desde tu banca en línea o app de tu banco.'),
  START: i18n.gettext('Empezar'),
  DATA_FOR_YOUR_INVOCE: i18n.gettext('Dados para a sua nota fiscal'),
  PURCHASE_NOW_AND_PAY_LATER: i18n.gettext('Compra ahora, paga después'),
  PAY_WITH_MERCADO_CREDIT: i18n.gettext('Elige Meses sin Tarjeta para pagar'),
  FREE_SHIPPING: i18n.gettext('Gratis'),
  PICKUP_SHIPPING: i18n.gettext('Retiro en local del vendedor'),
  FREE: i18n.gettext('Gratis'),
  NOT_EMPTY_FIELD: i18n.gettext('Este campo no puede estar vacio'),
  INVALID_EMAIL_FIELD: i18n.gettext('El e-mail ingresado no es válido'),
  INVALID_VALUE: i18n.gettext('Este dato es inválido'),
  GENERAL_CONDITIONS: i18n.gettext('Condiciones generales'),
  HELP_TO_PROTECT_YOUR_PURCHASES: i18n.gettext('Ayuda para proteger tus compras'),
  OFF: i18n.gettext('OFF'),
  DISCOUNT: i18n.gettext('Descuento'),
  COMPLETE_THIS_FIELD: i18n.gettext('Completa este campo.'),
  COMPLETE_THIS_DATA: i18n.gettext('Completa este dato'),
  APPROVAL_CODE: i18n.gettext('Código de aprobación'),
  COMPLETED_CODE: i18n.gettext('Ingresa el código completo.'),
  COMPLETED_NUMBER: i18n.gettext('Ingresa el número completo.'),
  INPUT_HELPER_CODE_YAPE: i18n.gettext('Encuéntralo en tu app de Yape.'),
  INPUT_HELPER_EMAIL_YAPE: i18n.gettext('Solo te enviaremos información del pago para que la tengas disponible.'),
  INVALID_NUMBER_YAPE: i18n.gettext('Ingresa el número asociado a tu cuenta Yape.'),
  EXPIRED_CODE: i18n.gettext('El código ingresado es inválido o expiró.'),
  YAPE_CREATE_CARD_TOKEN_ERROR: i18n.gettext('Hubo un error al procesar el pago. Por favor, intenta nuevamente.'),
  DOES_NOT_INCLUDE_PROTECTED_PURCHASE: i18n.gettext('No incluye Compra Protegida'),
  YOU_LOGGED_IN_AS: i18n.gettext('Ingresaste como:'),
  LABEL_NATURAL_NAME: i18n.gettext('Nombre'),
  LABEL_LAST_NAME: i18n.gettext('Apellido'),
  LABEL_IDENTIFICATION_NUMBER: i18n.gettext('Documento'),
  LABEL_EMAIL: i18n.gettext('E-mail'),
  LABEL_LEGAL_NAME: i18n.gettext('Razón social'),
  LABEL_ZIPCODE: i18n.gettext('Código postal'),
  LABEL_ENTITY: i18n.gettext('Tipo de persona'),
  LABEL_PHONE: i18n.gettext('Celular'),
  LABEL_STATE: i18n.gettext('Provincia'),
  LABEL_CITY: i18n.gettext('Ciudad'),
  LABEL_NEIGHBORHOOD: i18n.gettext('Barrio'),
  LABEL_STREET: i18n.gettext('Calle/Avenida'),
  LABEL_NUMBER: i18n.gettext('Número'),
  LABEL_NO_NUMBER: i18n.gettext('Sin número'),
  LABEL_COMPLEMENT: i18n.gettext('Piso/Departamento (opcional)'),
  PLACEHOLDER_LEGAL_NAME: i18n.gettext('Ej.: Empresa ML Ltda'),
  PLACEHOLDER_ZIPCODE: i18n.gettext('Ej.: 01310-200'),
  PLACEHOLDER_NEIGHBORHOOD: i18n.gettext('Ej.: Palermo'),
  PLACEHOLDER_STREET: i18n.gettext('Ej.: Av. Las Heras'),
  PLACEHOLDER_NUMBER: i18n.gettext('Ej.: 148'),
  PLACEHOLDER_COMPLEMENT: i18n.gettext('Ej.: 2do C'),
  PLACEHOLDER_NATURAL_NAME: i18n.gettext('Ej.: María Clara'),
  PLACEHOLDER_LAST_NAME: i18n.gettext('Ej.: Lopez Suarez'),
  PLACEHOLDER_NATURAL_FIRST_NAME: i18n.gettext('Ej.: María'),
  PLACEHOLDER_NATURAL_LAST_NAME: i18n.gettext('Ej.: López'),
  PLACEHOLDER_PHONE: i18n.gettext('Ej.: 321 1234567'),
  PLACEHOLDER_EMAIL: i18n.gettext('Ej.: nombre@email.com'),
  PLACEHOLDER_STATE: i18n.gettext('Ej.: Buenos Aires'),
  PLACEHOLDER_CITY: i18n.gettext('Ej.: Buenos Aires'),
  PLACEHOLDER_NO_NUMBER: i18n.gettext('S/N'),
  PLACEHOLDER_CPF: i18n.gettext('999.999.999-99'),
  PLACEHOLDER_CNPJ: i18n.gettext('99.999.999/9999-99'),
  INVALID_IDENTIFICATION_NUMBER: i18n.gettext('Ingresa un documento válido.'),
  UNCOMPLETED_IDENTIFICATION_NUMBER: i18n.gettext('Ingresa el documento completo.'),
  INVALID_FIRST_NAME: i18n.gettext('Ingresa un nombre válido.'),
  INVALID_LAST_NAME: i18n.gettext('Ingresa un apellido válido.'),
  INVALID_LEGAL_NAME: i18n.gettext('Ingresa la razón social completa.'),
  INVALID_PHONE_NUMBER: i18n.gettext('Ingresa un celular válido.'),
  INVALID_ZIPCODE: i18n.gettext('Ingresa el CEP completo.'),
  INVALID_STATE: i18n.gettext('Selecciona un estado.'),
  INVALID_CITY: i18n.gettext('Ingresa una ciudad válida.'),
  INVALID_NEIGHBORHOOD: i18n.gettext('Ingresa un barrio válido.'),
  INVALID_STREET: i18n.gettext('Ingresa una calle válida.'),
  REQUIRED_FORM_FIELD: i18n.gettext('Completa este campo.'),
  SELECT_STATE: i18n.gettext('Selecciona'),
  FILL_FIELD: i18n.gettext('Ingresa los datos'),
  NO_ADDRESS_MODIFY: i18n.gettext('No guardaremos o modificaremos la dirección en tu perfil.'),
  FILL_ADDRESS_INFO: i18n.gettext('Ingresa la dirección de facturación'),
  TO_GO_BACK: i18n.gettext('Volver'),
  NO_ZIPCODE: i18n.gettext('No sé el CEP'),
  FIND_ZIPCODE: i18n.gettext('Buscar código postal'),
  ZIPCODE_MODAL_TITLE_ADDRESS: i18n.gettext('Busca tu código postal'),
  ZIPCODE_MODAL_TITLE_LOCATION: i18n.gettext('Selecciona tu código postal'),
  MP_WALLET_INVESTED_GBM: i18n.gettext('Mercado Pago Wallet + Invertido en GBM.'),
  INVESTED_GBM: i18n.gettext('Invertido en GBM.'),
  STEP_OF: (val1, val2) => i18n.pgettext('mp-cow', 'Paso {0} de {1}', val1, val2),

  // INSTALLMENTS MIGRATION
  INSTALLMENT_OF: i18n.gettext('Una cuota de'),
  INSTALLMENTS_OF: (installments) => i18n.gettext('{0} cuotas de', installments),
  INSTALLMENT_TOTAL: i18n.gettext(', total de'),
  THIRD_PARTY_DISCLAIMER: i18n.gettext('* Si hay intereses, los aplicará tu banco.'),
  THIRD_PARTY_STEP_TITLE: (stepTitle) => i18n.gettext('{0}. Si hay intereses, los aplicará tu banco.', stepTitle),
  THIRD_PARTY_AMOUNT_LABEL: i18n.gettext('Los intereses serán aplicados por tu entidad bancaria.'),

  // CARD FORM MIGRATION
  EXCLUDE_PAYMENT_METHOD_MESSAGE: i18n.gettext('El vendedor no acepta esta tarjeta.'),
  EXCLUDE_PAYMENT_TYPE_MESSAGE: i18n.gettext('El vendedor no acepta este tipo de tarjeta.'),
  YOU_ARE_USING_YOUR_ACCOUNT: (name) =>  i18n.gettext('{0}, estás usando tu cuenta. Abrir opciones de cuenta', name),
  CLOSE_ACCOUNT_OPTION: i18n.gettext('Cerrar opciones de cuenta'),
  YOU_HAVE_MADE_THIS_PAYMENT_RECENTLY: i18n.gettext('Recién hiciste este pago'),
  GO_TO_ACTIVITY: i18n.gettext('Ir a Actividad'),
  PAY_AGAIN: i18n.gettext('Pagar de nuevo'),
  IF_NEEDED_CONSULT_THE_DETAILS_IN_ACTIVITY: i18n.gettext('Si lo necesitas, consulta los detalles en \"Actividad\".'),
  CONSULT_THE_SELLER_IF_YOU_NEED_MORE_INFORMATION: i18n.gettext('Consulta al vendedor si necesitas más información.'),

  // REVIEW EXPRESS
  RECOMMENDED_PAYMENT_METHOD: i18n.gettext('RECOMENDADO'),
  REVIEW_EXPRESS_TITLE: i18n.gettext('¿Cómo quieres pagar?'),
  INSTALLMENTS_LABEL: () => i18n.pgettext('mp-cow', 'Cuotas'),
  INSTALLMENTS_PLACEHOLDER: i18n.gettext('Selecciona'),
  INSTALLMENTS_IS_REQUIRED: i18n.gettext('Selecciona el número de cuotas.'),
  CVV_LABEL: i18n.gettext('Código de seguridad'),
  CVV_INCOMPLETE: i18n.gettext('Ingresa el código completo.'),
  CVV_IS_REQUIRED: i18n.gettext('Completa este campo.'),
  CVV_HELPER: (digits, sideCard) => i18n.gettext('Es un número de {0} dígitos. Lo encuentras al {1} de tu tarjeta o en la app de tu banco o billetera.', digits, sideCard),
  CVV_HELPER_BACK: i18n.gettext('dorso'),
  CVV_HELPER_FRONT: i18n.gettext('frente'),
  CHANGE_PAYMENT_METHOD: i18n.gettext('Elegir otro medio de pago'),
  PARTICULAR_CONDITIONS_LINK: i18n.gettext('condiciones particulares'),
  BANK_INTERESTS: i18n.gettext('* Si hay intereses, los aplicará tu banco.'),
  LEGALS_LINK: i18n.gettext('Términos y condiciones'),
  GENERAL_TERMS_LINK: () => i18n.pgettext('mp-cow', 'términos generales'),
  GENERAL_TERMS_AND_CONDITIONS: (settings) => i18n.jsx.gettext('Al finalizar, estás aceptando los {0} y las {1} de este préstamo.', settings),
  CONSUMER_CREDITS_NEXT_DUE_DATE: (settings) => i18n.jsx.gettext('La primera cuota vence el {0}.', settings),
  CONSUMER_CREDITS_RATE: (cat, annual) => i18n.gettext('CAT: {0} sin IVA. Tasa de interés anual: {1}. Sistema de amortización francés con mensualidad final fija.', cat, annual),
  CONSUMER_CREDITS_LEGALS_MLA: () => i18n.gettext('Mercado Pago actúa solo en calidad de correspondiente bancario de instituciones financieras asociadas. La oferta y el monto de la oferta podrán modificarse, en cualquier momento, a criterio de las instituciones financieras.'),
  CONSUMER_CREDITS_LEGALS_MLB: () => i18n.gettext('O Mercado Pago não realiza a prestação de serviços privativos de Instituição Financeira. O Mercado Pago, na qualidade de correspondente bancário no país, oferece aos seus usuários a possibilidade de contratação de crédito diretamente com a Instituição Financeira Parceira. \nCrédito sujeito à aprovação. A oferta, valores e condições da oferta poderão ser alterados a qualquer momento, a critério da Instituição Financeira.'),
  CONSUMER_CREDITS_LEGALS_MLM: (settings) => i18n.jsx.gettext('Este producto crediticio es ofrecido por Mercado Lending, S.A. de C.V. ("Mercado Crédito"), y se encuentra sujeto a la aprobación de análisis de riesgo de Mercado Crédito sin que éste se encuentre obligado a otorgar la línea de crédito y/o préstamo. Para mayor información incluyendo los requisitos de contratación, el costo y concepto de las comisiones aplicables, consulta los {0}. La oferta de los productos se realiza a través de la plataforma operada por MP Agregador, S. de R.L. de C.V.', settings),
  // TOOLTIP MESSAGE
  PSE_TOOLTIP: i18n.gettext('Elige si quieres pagar con tu nombre personal (persona natural) o a nombre de una empresa (persona jurídica).'),
  // ZIPCODE MODAL
  ZIP_CODE_ACRONYM: i18n.gettext('CP'),
  PRICE: i18n.gettext('Precio'),
  ADDITION: i18n.gettext('Adición'),
  PCJ_TOOLTIP_MESSAGE: i18n.gettext('Es una diferenciación en el precio según el número de cuotas que elegiste.'),
});
