const logger = require('nordic/logger')('melidata');

const { COMPONENT_ID, SESSION_TYPE } = require('../../constants/app');

const appendAdditionalData = (basePayload, additionalData) => {
  const is_session_scoped = additionalData?.userSession?.method === SESSION_TYPE.SCOPED;
  return {...basePayload, is_session_scoped};
}

const getData = (components, additionalData) => {
  let tracking = null;
  if (components) {
    const trackingComponent = components.find((component) => component.id === COMPONENT_ID.TRACKING);

    if (trackingComponent && trackingComponent.value && trackingComponent.value.melidata) {
      const melidataPayload = appendAdditionalData(trackingComponent.value.melidata.data, additionalData);
      tracking = {
        path: trackingComponent.value.melidata.path,
        payload: melidataPayload,
        experiments: trackingComponent.value.melidata.experiments,
      };
    } else {
      logger.info('Missing melidata tracking component');
    }
  }

  return tracking;
};

module.exports = {
  getData,
};
