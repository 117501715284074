/* eslint-disable react/prop-types */
const React = require('react');

const { defaultProps, getErrorProps } = require('../helper');
const Select = require('../../../components/Select');
const OptionList = require('../../../components/OptionsList');
const IssuersRow = require('../../../components/IssuersRow');
const InstallmentsRow = require('../../../components/InstallmentsRow');
const CommonRow = require('../../../components/CommonRow');
const SelectExpirationDate = require('../../../components/SelectExpirationDate');
const SelectState = require('../../../components/SelectState');
const SelectInstallmentsCredits = require('../../../components/SelectInstallmentsCredits');
const SelectInstallmentCardForm = require('../../../components/SelectInstallmentCardForm');
const SelectDynamicFields = require('../../../components/SelectDynamicFields');
const SelectInstallments = require('../../../components/SelectInstallments');
const SelectInstallmentsDynamic = require('../../../components/SelectInstallmentsDynamic');
const SelectInstallmentsCreditsDynamic = require('../../../components/SelectInstallmentsCreditsDynamic');
const List = require('../../../components/List');
const ListItem = require('../../../components/ListItem');
const SelectBounded = require('../../../components/SelectBounded');
const SwitchButtonNavigator = require('../../../components/SwitchButtonNavigator');
const SwitchAlternativePaymentMethod = require('../../../components/SwitchAlternativePaymentMethod');
const RadioListWithCombination = require('../../../components/RadioListWithCombination');

module.exports = {
  select: (props) => {
    const options = props.value.map((opt) => ({
      label: opt.label,
      selected: opt.selected,
      value: opt.id ? `[${opt.id}]` : '',
    }));

    return (
      <Select
        {...defaultProps(props)}
        {...getErrorProps(props)}
        id={props.id}
        name={props.name}
        label={props.label}
        deviceType={props.deviceType}
        options={options}
        showSearch={props.showSearch}
        placeholder={props?.placeholder}
        showPlaceholder={props?.showPlaceholder}
      />
    );
  },
  select_installment_express: (props) => {
    const options = props.value.map((opt) => {
      const totalWithInterest = opt.label.match(/\(([^)]+)\)/)?.[1];

      return {
        label: opt.label,
        total: opt.total,
        totalPurchase: opt.total_purchase,
        selected: opt.selected,
        id: opt.id,
        interestFree: opt.interest_free,
        showInterest: opt.show_interest,
        value: opt.id ? `[${opt.id}]` : '',
        summaryLabel: opt.summary_label,
        interestAmount: opt.interest_amount,
        totalWithInterest,
        additionalInfoLabel: opt.financing_cost_label,
        additionalInfoValue: opt.financing_cost_value,
        message: opt.message,
        additionalInfo: null,
      }
    });

    return (
      <SelectInstallments
        {...defaultProps(props)}
        {...getErrorProps(props)}
        id={props.id}
        name={props.name}
        label={props.label}
        updateSummary
        deviceType={props.deviceType}
        options={options}
        stateoptions={props.state_options}
      />
    );
  },
  select_installments_dynamic: (props) => {
    const options = props.value.map((opt) => ({
      label: opt.label,
      total: opt.total,
      totalPurchase: opt.total_purchase,
      selected: opt.selected,
      id: opt.id,
      interestFree: opt.interest_free,
      showInterest: opt.show_interest,
      value: opt.id ? `[${opt.id}]` : '',
      summaryLabel: opt.summary_label,
      additionalInfoLabel: opt.financing_cost_label,
      additionalInfoValue: opt.financing_cost_value,
      message: opt.message,
      additionalInfo: null,
    }));

    return (
      <SelectInstallmentsDynamic
        {...defaultProps(props)}
        {...getErrorProps(props)}
        id={props.id}
        showPlaceholder
        name={props.name}
        label={props.label}
        updateSummary
        deviceType={props.deviceType}
        options={options}
        stateoptions={props.state_options}
      />
    );
  },
  select_dynamic_fields: (props) => {
    const options = props.value.map((opt) => ({
      label: opt.label,
      value: `[${opt.id}]`,
      toShow: opt.to_show,
      toHide: opt.to_hide,
      additionalInfo: opt.additional_info || null,
    }));

    return (
      <SelectDynamicFields
        {...defaultProps(props)}
        {...getErrorProps(props)}
        id={props.id}
        name={props.name}
        label={props.label}
        deviceType={props.deviceType}
        options={options}
      />
    );
  },
  select_issuer: (props) => {
    const options = props.value.map((opt) => ({
      id: opt.id,
      name: props.name,
      value: `[${opt.id}]`,
      issuer: opt.value[0].alt,
      url: opt.value[0].image_url,
      nextPage: opt.next_page,
    }));

    return <OptionList {...defaultProps(props)} id={props.id} options={options} component={IssuersRow} />;
  },
  select_installments: (props) => {
    const options = props.value.map((gDetail) => {
      const installment = gDetail.value[0].text;
      const amount = gDetail.value[1].text;
      const totalAmount = gDetail.value[2].value;

      return {
        id: gDetail.id,
        name: props.name,
        value: `[${gDetail.id}]`,
        amount,
        installment,
        totalAmount,
        nextPage: gDetail.next_page,
      };
    });

    return (
      <OptionList
        {...defaultProps(props)}
        id={props.id}
        label={props.label}
        options={options}
        component={InstallmentsRow}
      />
    );
  },
  select_row: (props) => {
    const options = props.value.map((opt) => ({
      id: opt.id,
      name: props.name,
      value: `[${opt.id}]`,
      components: opt.value,
      siteId: props.siteId, // Coming from Optimus (Needed for CreditCardIcon)
      nextPage: opt.next_page,
      disabled: opt.disabled,
      trackEvent: opt.track_event,
      withActionText: opt.with_action_text,
      kind: opt.kind,
      withSwitch: opt?.with_switch,
      isCombination: opt?.is_combination,
      withBottomRow: opt?.with_bottom_row ?? [],
      withEmailConfirmation: opt?.with_email_confirmation,
      history: props.history,
    }));

    return (
      <OptionList
        {...defaultProps()}
        {...props}
        id={props.id}
        className={props.id}
        options={options}
        component={CommonRow}
      />
    );
  },
  select_row_combinations: (props) => {
    const { value, deviceType } = props;
    const items = value.map(({ title_row: title, description, id, icon, sub_text, title_full_text }) => ({
      id,
      title: title?.label,
      fullTitle: title_full_text,
      description: description?.label,
      paymentMethodId: icon?.data?.icon_uitype || icon?.data?.payment_method_id,
      promotionLabel: sub_text?.label,
    }));
    return <RadioListWithCombination items={items} deviceType={deviceType}/>;
  },
  select_extra_info_row: (props) => {
    const [row, extraInfoData] = props.value;
    const [extraInfo] = extraInfoData?.value;

    const options = [
      {
        id: row.id,
        name: props.name,
        value: `[${row.id}]`,
        components: row.value,
        siteId: props.siteId, // Coming from rowimus (Needed for CreditCardIcon)
        nextPage: row.next_page,
        disabled: row.disabled,
        trackEvent: row.track_event,
        withActionText: row.with_action_text,
        kind: row.kind,
        withSwitch: row?.with_switch,
        withBottomRow: row?.with_bottom_row ?? [],
        history: props.history,
      },
    ];

    return (
      <OptionList
        {...defaultProps(props)}
        id={props.id}
        className={props.id}
        options={options}
        component={CommonRow}
        extraInfo={extraInfo}
      />
    );
  },
  select_bounded_payment_method: (props) => {
    const options = props.value.map((opt) => ({
      id: opt.id,
      name: props.name,
      value: `[${opt.id}]`,
      components: opt.value,
      siteId: props.siteId, // Coming from Optimus (Needed for CreditCardIcon)
      nextPage: opt.next_page,
      disabled: opt.disabled,
      isCombination: opt?.is_combination,
    }));

    return <SelectBounded className={props.id} options={options} component={CommonRow} label={props.label} />;
  },
  select_payment_off: (props) => {
    const options = props.value.map((opt) => ({
      id: opt.id,
      name: props.name,
      value: `[${opt.id}]`,
      components: opt.value,
      siteId: props.siteId, // Coming from Optimus (Needed for CreditCardIcon)
      nextPage: opt.next_page,
    }));

    return (
      <OptionList {...defaultProps(props)} className="group-payment-off" options={options} component={CommonRow} />
    );
  },
  select_expiration_date: (props) => {
    const monthOptions = Array(12)
      .fill()
      .map((value, index) => {
        let month = `0${index + 1}`;
        if (month.length > 2) {
          month = month.substring(month.length - 2, month.length);
        } // keep two chars length
        return {
          label: month,
          value: month,
          selected: false,
          name: month,
        };
      });
    const year = new Date().getFullYear();
    const yearOptions = Array(50)
      .fill()
      .map((value, index) => {
        const yearValue = `${index + year}`;
        return {
          label: yearValue,
          value: yearValue.substring(2, 4),
          selected: false,
          name: yearValue,
        };
      });

    return (
      <SelectExpirationDate
        {...defaultProps(props)}
        {...getErrorProps(props)}
        id={props.id}
        className={props.id}
        deviceType={props.deviceType}
        monthOptions={monthOptions}
        yearOptions={yearOptions}
        component={CommonRow}
        label={props.label}
      />
    );
  },
  select_state: (props) => {
    const options = props.value.map((opt) => ({
      label: opt.label,
      value: `[${opt.id}]`,
      toShow: opt.to_show,
      toHide: opt.to_hide,
    }));

    return (
      <SelectState
        {...defaultProps(props)}
        {...getErrorProps(props)}
        id={props.id}
        name={props.name}
        label={props.label}
        value={props.value}
        deviceType={props.deviceType}
        options={options}
        showSearch={props.showSearch}
        defaultValue={props.defaultValue}
      />
    );
  },
  select_installments_credits: (props) => {
    const options = props.value.map((opt) => ({
      label: opt.label,
      total: opt.total,
      totalPurchase: opt.total_purchase,
      selected: opt.selected,
      id: opt.id,
      value: opt.id ? `[${opt.id}]` : '',
      summaryLabel: opt.summary_label,
      financialCostLabel: opt.financing_cost_label,
      financialCostValue: opt.financing_cost_value,
      detail: opt.detail,
      contractUrl: opt.contract_url,
      tycUrl: opt.tyc_url,
      message: opt.message,
      interestFree: opt.interest_free,
      showInterest: opt.show_interest,
    }));

    return (
      <SelectInstallmentsCredits
        {...defaultProps(props)}
        {...getErrorProps(props)}
        id={props.id}
        showPlaceholder
        name={props.name}
        label={props.label}
        updateSummary
        deviceType={props.deviceType}
        options={options}
        stateoptions={props.state_options}
      />
    );
  },
  select_installments_credits_dynamic: (props) => {
    const options = props.value.map((opt) => ({
      label: opt.label,
      total: opt.total,
      totalPurchase: opt.total_purchase,
      selected: opt.selected,
      id: opt.id,
      value: opt.id ? `[${opt.id}]` : '',
      summaryLabel: opt.summary_label,
      financialCostLabel: opt.financing_cost_label,
      financialCostValue: opt.financing_cost_value,
      detail: opt.detail,
      contractUrl: opt.contract_url,
      tycUrl: opt.tyc_url,
      message: opt.message,
      interestFree: opt.interest_free,
      showInterest: opt.show_interest,
    }));

    return (
      <SelectInstallmentsCreditsDynamic
        {...defaultProps(props)}
        {...getErrorProps(props)}
        id={props.id}
        showPlaceholder
        name={props.name}
        label={props.label}
        updateSummary
        deviceType={props.deviceType}
        options={options}
        stateoptions={props.state_options}
      />
    );
  },
  /** Temporal: Para Experimento de Unificación de CardForm */
  select_installment_cardform: (props) => (
    <SelectInstallmentCardForm
      {...defaultProps(props)}
      {...getErrorProps(props)}
      id={props.id}
      name={props.name}
      label={props.label}
      deviceType={props.deviceType}
    />
  ),
  list: (props) => <List {...defaultProps(props)} {...props} />,
  list_item: (props) => <ListItem {...defaultProps(props)} {...props} />,
  switch_combination: ({ id, checked, history }) => (
    <SwitchButtonNavigator {...defaultProps()} id={id} history={history} value={checked} />
  ),
  switch_alternative_payment_method: ({ id, checked }) => (
    <SwitchAlternativePaymentMethod {...defaultProps()} id={id} value={checked} />
  ),
};
