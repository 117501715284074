module.exports = {
  REVIEW_EXPRESS: {
    NAME: 'reviewexpress',
    ACTION: {
      PAY: 'pay',
      LOADED: 'loaded',
      CHANGED: 'changed',
    },
    AVAILABLE_TAGS: {
      ACTION: ['pay', 'loaded', 'changed'],
      DEVICE: ['desktop', 'mobile'],
    },
  },
};
