const React = require('react');
const PropTypes = require('prop-types');

const { bindActionCreators } = require('redux');
const { Modal } = require('@andes/modal');
const { Text } = require('@andes/typography');
const { Button } = require('@andes/button');

const duplicatedPaymentActions = require('../../spa/actions/duplicatedPayment');
const loadingActions = require('../../spa/actions/loading');
const animationsActions = require('../../spa/actions/animations');
const actionsTypes = require('../../spa/actions/types');
const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');
const translation = require('../../translation');

const DuplicatedPaymentModal = (props) => {
  const translations = translation(props.i18n);

  const uncheckSelectedOption = React.useCallback(() => {
    const inputsChecked = document.querySelectorAll('input:checked.u-hide');

    if (!inputsChecked?.length) {
      return;
    }

    Array.from(inputsChecked).forEach((input) => {
      input.checked = false;
    });
  }, []);

  const closeModal = React.useCallback(() => {
    props.closeDuplicatedPaymentModal();
    uncheckSelectedOption();
  }, [props, uncheckSelectedOption]);

  const payAgain = React.useCallback(() => {
    // TODO: add metrics here

    props.allowDuplicatedPayment();
    uncheckSelectedOption();

    props.disableLoading();
    props.loaderStopAnimationReset();
  }, [props, uncheckSelectedOption]);

  const onClose = React.useCallback(() => {
    // TODO: add metrics here

    closeModal();
  }, [closeModal]);

  const understood = React.useCallback(() => {
    // TODO: add metrics here

    closeModal();
  }, [closeModal]);

  const goToActivity = React.useCallback(() => {
    // TODO: add metrics here

    const mpActivitiesUrl = `https://${props.mpBaseDomain}/activities`;
    window.location.replace(mpActivitiesUrl);

    closeModal();
  }, [closeModal, props.mpBaseDomain]);

  return (
    <Modal
      open={props.show}
      title={translations.YOU_HAVE_MADE_THIS_PAYMENT_RECENTLY}
      className="cow-duplicated-payment-modal"
      type="card"
      onClose={onClose}
      closable={props.paidWithALoggedUser}
      closeButtonSrLabel={translations.CLOSE}
      actions={{
        fixed: false,
        primary: props.paidWithALoggedUser ? (
          <Button onClick={goToActivity}>{translations.GO_TO_ACTIVITY}</Button>
        ) : (
          <Button onClick={understood}>{translations.I_UNDERSTAND}</Button>
        ),
        secondary: (
          <Button onClick={payAgain} hierarchy="transparent">
            {translations.PAY_AGAIN}
          </Button>
        ),
      }}
    >
      <Text size="m" className="cow-duplicated-payment-modal__message">
        {props.paidWithALoggedUser
          ? translations.IF_NEEDED_CONSULT_THE_DETAILS_IN_ACTIVITY
          : translations.CONSULT_THE_SELLER_IF_YOU_NEED_MORE_INFORMATION}
      </Text>
    </Modal>
  );
};

DuplicatedPaymentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  paidWithALoggedUser: PropTypes.bool.isRequired,
  closeDuplicatedPaymentModal: PropTypes.func.isRequired,
  allowDuplicatedPayment: PropTypes.func.isRequired,
  disableLoading: PropTypes.func.isRequired,
  loaderStopAnimationReset: PropTypes.func.isRequired,
  mpBaseDomain: PropTypes.string.isRequired,
  i18n: PropTypes.shape({ gettext: PropTypes.func }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  closeDuplicatedPaymentModal: bindActionCreators(duplicatedPaymentActions.closeDuplicatedPaymentModal, dispatch),
  allowDuplicatedPayment: bindActionCreators(duplicatedPaymentActions.allowDuplicatedPayment, dispatch),
  disableLoading: bindActionCreators(loadingActions.disableLoading, dispatch),
  loaderStopAnimationReset: bindActionCreators(animationsActions[actionsTypes.LOADER_STOP_ANIMATION_RESET], dispatch),
});

const mapStateToProps = (state) => ({
  show: state.duplicatedPayment.show,
  paidWithALoggedUser: state.duplicatedPayment.paidWithALoggedUser,
  mpBaseDomain: state.configurations.platform.domain,
});

module.exports = connectToReduxAndInjectI18n(DuplicatedPaymentModal, mapStateToProps, mapDispatchToProps);
