const { sendDatadogMetric } = require('./api');
const { REVIEW_EXPRESS } = require('../../constants/metrics');

const sendReviewExpressMetric = ({ action, device }) => {
  const tags = { action, device };
  sendDatadogMetric({ tags });
};

const sendReviewExpressLoadMetric = ({ device }) => {
  sendReviewExpressMetric({
    device,
    action: REVIEW_EXPRESS.ACTION.LOADED,
  });
};

const sendReviewExpressPayMetric = ({ device }) => {
  sendReviewExpressMetric({
    device,
    action: REVIEW_EXPRESS.ACTION.PAY,
  });
};

const sendReviewExpressChangedMetric = ({ device }) => {
  sendReviewExpressMetric({
    device,
    action: REVIEW_EXPRESS.ACTION.CHANGED,
  });
};

module.exports = {
  sendReviewExpressPayMetric,
  sendReviewExpressLoadMetric,
  sendReviewExpressChangedMetric,
};
