const { SUMMARY_ITEMS_TYPES } = require('../../constants/app');
const currencyUtil = require('./currency');

/**
 * Injects PCJ properties into the summary if applicable.
 *
 * **Rules**: MLB + purchase on credit card in installments and with interest
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.props - The properties object.
 * @param {Object} params.paymentMethod - The payment method object.
 * @param {Object} params.translations - The translations object.
 * @returns {Object} The updated properties object with PCJ properties injected into the summary if applicable (see rules).
 */
const ifCandidateInjectPropsIntoSummary = ({ props, paymentMethod, translations }) => {
  if (!props.step_model) {
    return props;
  }

  const hasSingleInstallment = !!paymentMethod?.installment;
  const installment = hasSingleInstallment
    ? paymentMethod?.installment?.quantity
    : props.express?.installments?.[paymentMethod.option_id]?.value;

  if (!installment) {
    return props;
  }

  const currentInstallment = hasSingleInstallment
    ? paymentMethod?.installment
    : paymentMethod?.installments[installment - 1];

  if (!currentInstallment?.is_pcj) {
    return props;
  }

  const interestTotalAmount = currentInstallment?.interest_total_amount;
  const installmentTotalAmount = currentInstallment?.installment_total_amount;

  const newItems = props.step_model.summary.items.map((item) => {
    if (item.type !== SUMMARY_ITEMS_TYPES.PRODUCT) {
      return item;
    }

    const originalPrice = item.price.originalPrice || item.price.text_value;
    const newItem = {
      ...item,
      price: {
        ...item.price,
        originalPrice,
        text_value: currencyUtil.parseAmount(currentInstallment?.total_items_amount_plus_interest, props.currency),
      },
      subItems: [
        {
          label: translations.PRICE,
          price: originalPrice,
        },
        {
          label: translations.ADDITION,
          price: currencyUtil.parseAmount(interestTotalAmount, props.currency),
          tooltipContent: translations.PCJ_TOOLTIP_MESSAGE,
        },
      ],
    };

    return newItem;
  });

  const newTotal = {
    ...props.step_model.summary.total,
    nameWeight: 'semibold',
    priceWeight: 'semibold',
    detail: `(${currencyUtil.parseAmount(installmentTotalAmount, props.currency)})`,
    detailColor: 'primary',
    detailDirection: 'column',
  };

  const propsWithPCJProperties = {
    ...props,
    step_model: {
      ...props?.step_model,
      summary: {
        ...props?.step_model?.summary,
        items: newItems,
        total: newTotal,
      },
    },
  };

  return propsWithPCJProperties;
};

module.exports = { ifCandidateInjectPropsIntoSummary };
