const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');
const { schema, useForm } = require('checkout-off-form-validation');
const { injectI18n } = require('nordic/i18n');

const { Form } = require('@andes/form');
const { Tooltip } = require('@andes/tooltip');
const { TextField } = require('@andes/textfield');
const { Card, CardContent } = require('@andes/card');
const { DropdownForm, DropdownItem } = require('@andes/dropdown');
const IconInfo = require('@andes/icons/Info16');

const FormActions = require('../../FormActions');
const InputIdentification = require('../../InputIdenfication');

const translate = require('../../../translation');
const stepActions = require('../../../spa/actions/step');
const inputValuesActions = require('../../../spa/actions/inputValues');
const { getQueryParams } = require('../../../utils/Dom');
const { formatDocument, formatString, formatPsePhone } = require('../../../utils/format');
const { CURRENT_INPUT_VALUES } = require('../../../spa/actions/types');
const { STEP_NEXT } = require('../../../spa/actions/types');

const {
  CHECKOUT_TYPE: { MODAL },
} = require('../../../../constants/commons');

const {
  PHONES: { MCO_PHONE_MAX_SIZE },
} = require('../../../../constants/app');

const {
  COLORS,
  APP: {
    DOCUMENTS: {
      CC,
      NIT,
      CE_PLACEHOLDER,
      CE_MASK_LENGTH,
      CC_PLACEHOLDER,
      CC_MASK_LENGTH,
      NIT_PLACEHOLDER,
      NIT_MASK_LENGTH,
    },
    ENTITY: { ASSOCIATION, INDIVIDUAL },
    FIELD_LENGTH: { MIN_PHONE_LENGTH, MAX_PHONE_LENGTH, MIN_NAME_LENGTH, MAX_NAME_LENGTH },
  },
} = require('../../../../constants');

const FormPersonalInfoPse = (props) => {
  const { flow, form_type, history, i18n, inputValuesAction, stepAction } = props;
  const { errors, clearAllErrors, handleSubmit, registerSchema, setFieldOptions } = useForm();

  const translations = translate(i18n);

  const formValues = props?.form_data?.values;
  const entityTypes = props?.form_data?.entity_types;
  const identificationTypes = props?.form_data?.identification_types?.map((t) => ({ title: t.id }));

  const getIdentificationFields = (document) => {
    let identificationPlaceholder;

    switch (document) {
      case NIT:
        identificationPlaceholder = NIT_PLACEHOLDER;
        break;
      case CC:
        identificationPlaceholder = CC_PLACEHOLDER;
        break;
      default:
        identificationPlaceholder = CE_PLACEHOLDER;
        break;
    }

    return { identificationPlaceholder };
  };

  const getIdentificationLengthValidation = (document) => {
    switch (document) {
      case NIT:
        return NIT_MASK_LENGTH;
      case CC:
        return CC_MASK_LENGTH;
      default:
        return CE_MASK_LENGTH;
    }
  };

  const [key, setKey] = React.useState(1);
  const [identificationNumber, setIdentificationNumber] = React.useState(
    formatDocument(formValues.identification_number, formValues.identification_type),
  );
  const [identificationSelection, setIdentificationSelection] = React.useState(
    identificationTypes.findIndex((t) => t.title === formValues.identification_type),
  );
  const [identificationType, setIdentificationType] = React.useState(formValues.identification_type || CC);
  const [entityType, setEntityType] = React.useState(identificationType === NIT ? ASSOCIATION : INDIVIDUAL);
  const [identificationFields, setIdentificationFields] = React.useState(getIdentificationFields(identificationType));

  const formFields = registerSchema(
    schema.shape({
      firstName: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: MIN_NAME_LENGTH, message: translations.INVALID_FIRST_NAME })
        .max({ length: MAX_NAME_LENGTH, message: translations.INVALID_FIRST_NAME })
        .onChange((e) => formatString(e.target.value, e.target))
        .validateOnChange(false),
      lastName: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: MIN_NAME_LENGTH, message: translations.INVALID_LAST_NAME })
        .max({ length: MAX_NAME_LENGTH, message: translations.INVALID_LAST_NAME })
        .onChange((e) => formatString(e.target.value, e.target))
        .validateOnChange(false),
      identificationNumber: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .document({ type: identificationType, message: translations.INVALID_IDENTIFICATION_NUMBER })
        .max({
          length: getIdentificationLengthValidation(identificationType),
          message: translations.INVALID_IDENTIFICATION_NUMBER,
        })
        .validateOnChange(false),
      phone: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .min({ length: MIN_PHONE_LENGTH, message: translations.INVALID_PHONE_NUMBER })
        .max({ length: MAX_PHONE_LENGTH, message: translations.INVALID_PHONE_NUMBER })
        .onChange((e) => formatPsePhone(e.target.value, e.target))
        .validateOnChange(false),
      email: schema
        .options()
        .required({ message: translations.REQUIRED_FORM_FIELD })
        .email({ message: translations.INVALID_EMAIL })
        .validateOnChange(false),
    }),
  );

  const setInputStateValues = React.useCallback(
    (values) => inputValuesAction[CURRENT_INPUT_VALUES](`${flow.step}_${form_type}_values`, values),
    [props],
  );

  const handleIdentificationTypeClick = (item) => {
    const identificationLength = getIdentificationLengthValidation(item.title);

    clearAllErrors();
    setIdentificationType(item.title);
    setIdentificationFields(getIdentificationFields(item.title));

    if (item.title === NIT) {
      setEntityType(ASSOCIATION);
    } else {
      setEntityType(INDIVIDUAL);
    }

    setFieldOptions('identificationNumber', {
      document: {
        type: item.title,
        message: translations.INVALID_IDENTIFICATION_NUMBER,
      },
      max: {
        length: identificationLength,
        message: translations.INVALID_IDENTIFICATION_NUMBER,
      },
    });
  };

  const handleEntityTypeClick = (entity, value) => {
    clearAllErrors();

    const entType = value ? value : ASSOCIATION;

    setEntityType(entType);
    setIdentificationNumber('');

    if (entType === ASSOCIATION) {
      setIdentificationSelection(2);
      setIdentificationType(NIT);
      setKey(key + 1);
    } else {
      setIdentificationSelection(0);
      setIdentificationType(CC);
      setKey(key + 1);
    }
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    setInputStateValues({
      email: data.email || formValues?.email,
      last_name: data.lastName,
      first_name: data.firstName,
      identification_number: data.identificationNumber,
      identification_type: identificationType,
      entity_type: entityType,
      phone: data.phone || formValues?.phone,
    });

    const urlParams = getQueryParams();
    const defaultData = { urlParams, type: flow.type };

    const formData = {
      ...data,
      email: data?.email || formValues?.email,
      identificationType,
      entityType,
      isNewInterface: true,
    };

    stepAction[STEP_NEXT](formData, flow.id, defaultData, flow.type, urlParams, history);
  };

  return (
    <div className={`${props.checkoutType === MODAL && 'scroller'}`}>
      <Card>
        <CardContent>
          <Form className="form_personal_info_pse" id={props.form}>
            <div className="tooltip-demo-container">
              <Tooltip
                autoHideDelay={0}
                closeIconLabel="Cerrar"
                content={translations.PSE_TOOLTIP}
                side="bottomLeft"
                type="dark"
              >
                <IconInfo color={COLORS.MP_ANDES_BLUE_500} />
              </Tooltip>
            </div>

            <DropdownForm
              value={entityType}
              className="form_personal_field"
              id="entity"
              label={translations.LABEL_ENTITY}
              placeholder="Seleccione una opcion"
              defaultValue={INDIVIDUAL}
              onChange={handleEntityTypeClick}
              menuAlignment={'bottom'}
            >
              {Object.entries(entityTypes).map(([key, value]) => (
                <DropdownItem title={value} value={key} />
              ))}
            </DropdownForm>

            {identificationType !== NIT && (
              <div className="form_personal_double_field">
                <>
                  <TextField
                    id="first_name"
                    className="form_personal_field"
                    label={translations.LABEL_NATURAL_NAME}
                    placeholder={translations.PLACEHOLDER_NATURAL_FIRST_NAME}
                    helper={errors?.firstName?.message}
                    modifier={errors?.firstName && 'error'}
                    defaultValue={formValues.first_name || ''}
                    {...formFields?.firstName}
                  />

                  <TextField
                    id="last_name"
                    className="form_personal_field"
                    label={translations.LABEL_LAST_NAME}
                    placeholder={translations.PLACEHOLDER_NATURAL_LAST_NAME}
                    helper={errors?.lastName?.message}
                    modifier={errors?.lastName && 'error'}
                    defaultValue={formValues.last_name || ''}
                    {...formFields?.lastName}
                  />
                </>
              </div>
            )}

            <InputIdentification
              key={key}
              id="identification_number"
              className="form_personal_field"
              label={translations.LABEL_IDENTIFICATION_NUMBER}
              placeholder={identificationFields.identificationPlaceholder}
              defaultValue={identificationNumber}
              defaultSelection={identificationSelection !== -1 ? identificationSelection : 0}
              identificationType={identificationType}
              list={{ content: identificationTypes, onClick: handleIdentificationTypeClick }}
              helper={errors?.identificationNumber?.message}
              modifier={errors?.identificationNumber && 'error'}
              inputProps={{
                type: 'text',
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              {...formFields?.identificationNumber}
            />
            <div className="second_form_personal_double_field">
              <TextField
                id="phone"
                className="form_personal_field_phone"
                label={translations.LABEL_PHONE}
                placeholder={translations.PLACEHOLDER_PHONE}
                helper={errors?.phone?.message}
                modifier={errors?.phone && 'error'}
                defaultValue={formatPsePhone(formValues.phone) || ''}
                maxLength={MCO_PHONE_MAX_SIZE}
                inputmode="numeric"
                pattern="[0-9]*"
                type="text"
                {...formFields?.phone}
              />
              <TextField
                id="email"
                className="form_personal_field_email"
                label={translations.EMAIL}
                placeholder={translations.PLACEHOLDER_EMAIL}
                helper={errors?.email?.message}
                modifier={errors?.email && 'error'}
                defaultValue={formValues.email || ''}
                disabled={formValues.email}
                {...formFields?.email}
              />
            </div>
          </Form>
        </CardContent>
      </Card>

      <FormActions.Container>
        <FormActions.BackButton text={translations.TO_GO_BACK} handleBack={() => window.history.back()} />
        <FormActions.SubmitButton text={translations.CONTINUE} handleSubmit={handleSubmit(onSubmit)} />
      </FormActions.Container>
    </div>
  );
};

FormPersonalInfoPse.propTypes = {
  siteId: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  form_type: PropTypes.string.isRequired,
  form_data: PropTypes.shape({
    values: PropTypes.shape({
      email: PropTypes.string,
      last_name: PropTypes.string,
      first_name: PropTypes.string,
      identification_type: PropTypes.string,
      identification_number: PropTypes.string,
      phone: PropTypes.string,
    }).isRequired,
    identification_types: PropTypes.array.isRequired,
    entity_types: PropTypes.array.isRequired,
  }).isRequired,
  stepAction: PropTypes.shape({
    [STEP_NEXT]: PropTypes.func.isRequired,
  }).isRequired,
  inputValuesAction: PropTypes.shape({
    [CURRENT_INPUT_VALUES]: PropTypes.func.isRequired,
  }).isRequired,
  flow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    back: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, props) => {
  const inputStateValues = state.inputValues.current[`${state.page.flow.step}_${props.form_type}_values`];
  return {
    flow: state.page.flow,
    checkoutType: state.configurations.checkoutType,
    form_data: {
      ...props.form_data,
      values: inputStateValues || props.form_data.values,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  stepAction: bindActionCreators(stepActions, dispatch),
  inputValuesAction: bindActionCreators(inputValuesActions, dispatch),
});

if (process.env.NODE_ENV === 'test') {
  module.exports = FormPersonalInfoPse;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(FormPersonalInfoPse));
}
