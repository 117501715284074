const { checkReauthentication } = require('../service/api');
const { SUBMIT_FORM_ID } = require('../../constants/app');

/**
 * ChallengeConfig function that builds login challenge configuration
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configuration - Frontend's environment configurations.
 * @param redirectUrl - desired url to use a return url from challenge.
 * @param components - step components.
 * @param hiddenComponents - step hidden_components.
 */
const twofactorConfig = (challengeRawData, configuration, redirectUrl, components, hiddenComponents) => {
  const { flowId, meliSessionId } = challengeRawData;

  return checkReauthentication(redirectUrl, flowId, meliSessionId).then(({ reauthUrl }) => ({
    challengeUrl: reauthUrl,
    submitFormId: SUBMIT_FORM_ID.TWO_FACTOR_FORM,
    popupSetup: {
      width: 500,
      height: 600,
      completeOnPostmessage: true,
    },
  }));
};

module.exports.twofactorConfig = twofactorConfig;
