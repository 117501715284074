const { createAction } = require('redux-actions');

const { trackInfo } = require('../../service/api');
const { localeFromSiteId } = require('../../utils/locale');
const { logErrorFromClient } = require('../../utils/logTags');
const { setCookie, deleteCookiesByKey } = require('../../utils/CookieUtils');
const { formatAmountWithInstallments } = require('../../utils/format');
const cardTokensService = require('../../service/cardTokens');

const {
  REQUEST_STARTED,
  REQUEST_FINISHED,
  EXPRESS_RESET_INSTALLMENTS,
  EXPRESS_UPDATE_INSTALLMENTS,
  EXPRESS_SET_INSTALLMENTS_ERROR,
  EXPRESS_SET_SECURE_FIELDS_FOCUS,
  EXPRESS_SET_SECURE_FIELDS_ERROR,
  EXPRESS_RESET_SECURE_FIELDS,
  EXPRESS_VALIDATE_CARD,
  EXPRESS_CARD_TOKEN,
  EXPRESS_RESET_CARD,
} = require('./types');

const {
  COOKIES,
  GATEWAY_ERROR_MAP: { INVALID_ESC },
  ERROR_SPA: { ACTIONS_ESC_VALIDATOR },
} = require('../../../constants/app');

const requestStarted = createAction(REQUEST_STARTED);
const requestFinished = createAction(REQUEST_FINISHED);

const expressReset = createAction(EXPRESS_RESET_CARD);
const expressValidate = createAction(EXPRESS_VALIDATE_CARD);
const expressResetInstallments = createAction(EXPRESS_RESET_INSTALLMENTS);
const expressUpdateInstallments = createAction(EXPRESS_UPDATE_INSTALLMENTS);
const expressSetInstallmentsError = createAction(EXPRESS_SET_INSTALLMENTS_ERROR);
const expressSetSecureFieldsFocus = createAction(EXPRESS_SET_SECURE_FIELDS_FOCUS);
const expressSetSecureFieldsError = createAction(EXPRESS_SET_SECURE_FIELDS_ERROR);
const expressResetSecureFields = createAction(EXPRESS_RESET_SECURE_FIELDS);

const updateInstallments = (paymentMethod, installment, siteId) => (dispatch) => {
  const { locale, currency } = localeFromSiteId(siteId);
  const price = formatAmountWithInstallments(siteId, locale, currency, installment.amount, installment.quantity);

  dispatch(
    expressUpdateInstallments({
      price,
      paymentMethod,
      installments: {
        [paymentMethod]: {
          price,
          value: installment.quantity,
          contract: installment.contract_url,
          error: false,
        },
      },
    }),
  );
};

const resetInstallments = (paymentMethod) => (dispatch) => {
  dispatch(
    expressResetInstallments({
      paymentMethod,
      installments: {
        [paymentMethod]: {
          price: '',
          value: '',
          contract: '',
          error: false,
        },
      },
    }),
  );
};

const setInstallmentsError = (paymentMethod, error) => (dispatch) => {
  dispatch(
    expressSetInstallmentsError({
      paymentMethod,
      installments: {
        [paymentMethod]: { error },
      },
    }),
  );
};

const setSecureFieldsFocus = (focus) => (dispatch) => {
  dispatch(
    expressSetSecureFieldsFocus({
      secureField: { focus },
    }),
  );
};

const setSecureFieldsError = (error) => (dispatch) => {
  dispatch(
    expressSetSecureFieldsError({
      secureField: { error },
    }),
  );
};

const resetSecureFields = () => (dispatch) => {
  dispatch(
    expressResetSecureFields({
      secureField: { error: '', focus: false },
    }),
  );
};

const resetExpressCard = (cardId) => (dispatch) => {
  dispatch(expressReset({ [cardId]: {} }));
};

const getExpressCartToken = (cardId) => (dispatch, getState) => {
  const currentState = getState();
  dispatch(expressValidate(currentState?.express?.[cardId]));
  return currentState?.express?.[cardId]?.cardTokenId;
};

const validateExpressCard = (cardId, esc) => (dispatch, getState) => {
  const currentState = getState();
  const { configurations, page } = currentState;
  const keyEsc = `${COOKIES.ESC}${cardId}`;

  if (!esc) {
    dispatch(expressReset({ [cardId]: {} }));
    return;
  }

  const data = {
    esc,
    card_id: cardId,
    require_esc: true,
    device: {
      fingerprint: {
        vendor_ids: [
          {
            name: 'browser',
            value: page?.data.internalDeviceId,
          },
        ],
      },
    },
  };

  dispatch(
    requestStarted({
      requestId: cardId,
    }),
  );

  cardTokensService
    .validateEsc(
      configurations?.publicKey,
      data,
      configurations?.deviceType,
      configurations?.testCredentials,
      configurations?.version,
    )
    .then((response) => {
      setCookie(keyEsc, response.esc);

      dispatch(
        expressValidate({
          [cardId]: {
            cardId,
            cardTokenId: response.id,
            cardIdsValidated: response.id ? 'VALID' : 'INVALID',
          },
        }),
      );

      dispatch(
        requestFinished({
          requestId: cardId,
          leaveLoading: false,
        }),
      );

      return Promise.resolve(response);
    })
    .catch((error) => {
      const isEscInvalid = error?.response?.data?.cause[0]?.code;

      deleteCookiesByKey(keyEsc);

      dispatch(
        requestFinished({
          requestId: cardId,
          leaveLoading: false,
        }),
      );

      dispatch(
        expressValidate({
          [cardId]: { cardId, cardTokenId: undefined, cardIdsValidated: 'INVALID' },
        }),
      );

      if (isEscInvalid === INVALID_ESC) {
        trackInfo({ message: 'cardToken: Invalid ESC' });
      } else {
        logErrorFromClient(error, ACTIONS_ESC_VALIDATOR, '[cardTokenEscPromise][EXPRESS_VALIDATE_CARD]');
        return Promise.reject(error);
      }
    });
};

/**
 * Export functions
 */
module.exports = {
  [EXPRESS_RESET_CARD]: resetExpressCard,
  [EXPRESS_VALIDATE_CARD]: validateExpressCard,
  [EXPRESS_CARD_TOKEN]: getExpressCartToken,
  [EXPRESS_RESET_INSTALLMENTS]: resetInstallments,
  [EXPRESS_UPDATE_INSTALLMENTS]: updateInstallments,
  [EXPRESS_SET_INSTALLMENTS_ERROR]: setInstallmentsError,
  [EXPRESS_SET_SECURE_FIELDS_FOCUS]: setSecureFieldsFocus,
  [EXPRESS_SET_SECURE_FIELDS_ERROR]: setSecureFieldsError,
  [EXPRESS_RESET_SECURE_FIELDS]: resetSecureFields,
};
