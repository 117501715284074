const { PAGE_NAME, URL, CHUNK } = require('../../constants/app');

const {
  DEVICE_TYPE: { MOBILE, DESKTOP },
  CHECKOUT_CASE: { GUEST, LOGGED, EXPRESS, DEFAULT },
} = require('../../constants/commons');

const steps = {
  CARD_FORM: {
    url: URL.CARD_FORM,
    pageName: PAGE_NAME.CARD_FORM,
    chunk: CHUNK.CARD_FORM,
    templates: {
      [MOBILE]: {
        default: require('../pages/card-form/templates/mobile'),
      },
      [DESKTOP]: {
        default: require('../pages/card-form/templates/desktop'),
      },
    },
    resolve: () => require.ensure([], (require) => require('../pages/card-form'), 'chunk.card_form'),
    webpack: () => require.resolveWeak('../pages/card-form'),
  },
  CARD_FORM_FULL: {
    url: URL.CARD_FORM_FULL,
    pageName: PAGE_NAME.CARD_FORM_FULL,
    chunk: CHUNK.CARD_FORM_FULL,
    resolve: () => require.ensure([], (require) => require('../pages/card-form-full'), 'chunk.card_form_full'),
    webpack: () => require.resolveWeak('../pages/card-form-full'),
  },
  IDENTIFICATION_FORM: {
    url: URL.IDENTIFICATION,
    pageName: PAGE_NAME.IDENTIFICATION_FORM,
    chunk: CHUNK.IDENTIFICATION_FORM,
    resolve: () => require.ensure([], (require) => require('../pages/identification-form'), 'chunk.identification_form'),
    webpack: () => require.resolveWeak('../pages/identification-form'),
  },
  EXTRA_DATA_FORM: {
    url: URL.EXTRA_DATA,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  ISSUER_FORM: {
    url: URL.ISSUER,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  INSTALLMENTS_FORM: {
    url: URL.INSTALLMENTS,
    pageName: PAGE_NAME.INSTALLMENTS_FORM,
    chunk: CHUNK.INSTALLMENTS_FORM,
    templates: {
      [MOBILE]: {
        default: require('../pages/installments-form/templates/mobile'),
      },
      [DESKTOP]: {
        default: require('../pages/installments-form/templates/desktop'),
      },
    },
    resolve: () => require.ensure([], (require) => require('../pages/installments-form'), 'chunk.installments_form'),
    webpack: () => require.resolveWeak('../pages/installments-form'),
  },
  SECURITY_CODE_FORM: {
    url: URL.SECURITY_CODE,
    pageName: PAGE_NAME.SECURITY_CODE_FORM,
    chunk: CHUNK.SECURITY_CODE_FORM,
    resolve: () => require.ensure([], (require) => require('../pages/security-code-form'), 'chunk.security_code_form'),
    webpack: () => require.resolveWeak('../pages/security-code-form'),
  },
  REVIEW_SPLIT_PAYMENT: {
    url: URL.REVIEW_SPLIT,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  REVIEW: {
    url: URL.REVIEW,
    pageName: PAGE_NAME.REVIEW,
    chunk: CHUNK.REVIEW,
    templates: {
      [MOBILE]: {
        [GUEST]: require('../pages/review/templates/mobile/guest'),
        [EXPRESS]: require('../pages/review/templates/mobile/express'),
        [DEFAULT]: require('../pages/review/templates/mobile'),
      },
      [DESKTOP]: {
        [GUEST]: require('../pages/review/templates/desktop/guest'),
        [EXPRESS]: require('../pages/review/templates/desktop/express'),
        [DEFAULT]: require('../pages/review/templates/desktop'),
      },
    },
    resolve: () => require.ensure([], (require) => require('../pages/review'), 'chunk.review'),
    webpack: () => require.resolveWeak('../pages/review'),
  },
  REVIEW_YAPE: {
    url: URL.YAPE,
    pageName: PAGE_NAME.YAPE,
    chunk: CHUNK.YAPE,
    resolve: () => require.ensure([], (require) => require('../pages/yape'), 'chunk.yape'),
    webpack: () => require.resolveWeak('../pages/yape'),
  },
  EXPRESS: {
    url: URL.EXPRESS,
    pageName: PAGE_NAME.EXPRESS,
    chunk: CHUNK.EXPRESS,
    resolve: () => require.ensure([], (require) => require('../pages/express'), 'chunk.express'),
    webpack: () => require.resolveWeak('../pages/express'),
  },
  CHECKOUT_EXPRESS_OFF_PAYMENT: {
    url: URL.CHECKOUT_EXPRESS_OFF_PAYMENT,
    pageName: PAGE_NAME.EXPRESS,
    chunk: CHUNK.EXPRESS,
    resolve: () => require.ensure([], (require) => require('../pages/express'), 'chunk.express'),
    webpack: () => require.resolveWeak('../pages/express'),
  },
  CONSUMER_CREDITS: {
    url: URL.CONSUMER_CREDITS,
    pageName: PAGE_NAME.CONSUMER_CREDITS,
    chunk: CHUNK.CREDITS,
    resolve: () => require.ensure([], (require) => require('../pages/consumer-credits'), 'chunk.credits'),
    webpack: () => require.resolveWeak('../pages/consumer-credits'),
  },
  PROCESSING: {
    url: URL.PROCESSING,
    pageName: PAGE_NAME.PROCESSING,
    chunk: CHUNK.PROCESSING,
    resolve: () => require.ensure([], (require) => require('../pages/processing'), 'chunk.processing'),
    webpack: () => require.resolveWeak('../pages/processing'),
  },
  CHALLENGE: {
    url: URL.CHALLENGE,
    pageName: PAGE_NAME.CHALLENGE,
    chunk: CHUNK.CHALLENGE,
    resolve: () => require.ensure([], (require) => require('../pages/challenge'), 'chunk.challenge'),
    webpack: () => require.resolveWeak('../pages/challenge'),
  },
  LOGIN: {
    url: URL.LOGIN,
    pageName: PAGE_NAME.LOGIN,
    chunk: CHUNK.LOGIN,
    resolve: () => require.ensure([], (require) => require('../pages/login'), 'chunk.login'),
    webpack: () => require.resolveWeak('../pages/login'),
  },
  LOGIN_CHALLENGE_REMEDY: {
    url: URL.LOGIN_CHALLENGE_REMEDY,
    pageName: PAGE_NAME.LOGIN_CHALLENGE_REMEDY,
    chunk: CHUNK.LOGIN_CHALLENGE_REMEDY,
    resolve: () =>
      require.ensure([], (require) => require('../pages/login-challenge-remedy'), 'chunk.login-challenge-remedy'),
    webpack: () => require.resolveWeak('../pages/login-challenge-remedy'),
  },
  LOGIN_REMEDY_CVV: {
    url: URL.LOGIN_REMEDY_CVV,
    pageName: PAGE_NAME.LOGIN_REMEDY_CVV,
    chunk: CHUNK.LOGIN_REMEDY_CVV,
    resolve: () => require.ensure([], (require) => require('../pages/login-remedy-cvv'), 'chunk.login-remedy-cvv'),
    webpack: () => require.resolveWeak('../pages/login-remedy-cvv'),
  },
  LOGIN_INCENTIVE: {
    url: URL.LOGIN_INCENTIVE,
    pageName: PAGE_NAME.LOGIN_INCENTIVE,
    chunk: CHUNK.LOGIN_INCENTIVE,
    resolve: () => require.ensure([], (require) => require('../pages/login-incentive'), 'chunk.login-incentive'),
    webpack: () => require.resolveWeak('../pages/login-incentive'),
  },
  ERROR: {
    url: URL.ERROR,
    pageName: PAGE_NAME.EXCEPTION,
    chunk: CHUNK.EXCEPTION,
    resolve: () => require.ensure([], (require) => require('../pages/exception'), 'chunk.exception'),
    webpack: () => require.resolveWeak('../pages/exception'),
  },
  FATAL: {
    url: URL.FATAL,
    pageName: PAGE_NAME.EXCEPTION,
    chunk: CHUNK.EXCEPTION,
    resolve: () => require.ensure([], (require) => require('../pages/exception'), 'chunk.exception'),
    webpack: () => require.resolveWeak('../pages/exception'),
  },
  REDIRECT: {
    url: URL.REDIRECT,
    pageName: null,
    chunk: null,
  },
  PAYMENT_OPTION_FORM: {
    url: URL.PAYMENT_OPTION_FORM,
    pageName: PAGE_NAME.PAYMENT_OPTION_FORM,
    chunk: CHUNK.PAYMENT_OPTION_FORM,
    resolve: () => require.ensure([], (require) => require('../pages/payment-option-form'), 'chunk.payment-option-form'),
    webpack: () => require.resolveWeak('../pages/payment-option-form'),
  },
  PAYMENT_OPTION_FORM_V2: {
    url: URL.PAYMENT_OPTION_FORM_V2,
    pageName: PAGE_NAME.PAYMENT_OPTION_FORM_V2,
    chunk: CHUNK.PAYMENT_OPTION_FORM_V2,
    templates: {
      [MOBILE]: {
        default: {
          [GUEST]: require('../pages/payment-option-form-v2/templates/mobile/guest'),
          [LOGGED]: require('../pages/payment-option-form-v2/templates/mobile/logged'),
        },
      },
      [DESKTOP]: {
        default: {
          [GUEST]: require('../pages/payment-option-form-v2/templates/desktop/guest'),
          [LOGGED]: require('../pages/payment-option-form-v2/templates/desktop/logged'),
        },
      },
    },
    resolve: () => require.ensure([], (require) => require('../pages/payment-option-form-v2'), 'chunk.payment-option-form-v2'),
    webpack: () => require.resolveWeak('../pages/payment-option-form-v2'),
  },
  PAYMENT_OPTION_FORM_COMBINATION: {
    url: URL.PAYMENT_OPTION_FORM_COMBINATION,
    pageName: PAGE_NAME.PAYMENT_OPTION_FORM_COMBINATION,
    chunk: CHUNK.PAYMENT_OPTION_FORM_COMBINATION,
    resolve: () =>
      require.ensure(
        [],
        (require) => require('../pages/payment-option-combination'),
        'chunk.payment-option-combination',
      ),
    webpack: () => require.resolveWeak('../pages/payment-option-combination'),
  },
  PAYMENT_OPTION_FORM_LOGIN_A: {
    url: URL.PAYMENT_OPTION_FORM_LOGIN_A,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  PAYMENT_OPTION_FORM_LOGIN_B: {
    url: URL.PAYMENT_OPTION_FORM_LOGIN_B,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  PAYMENT_OPTION_FORM_LOGIN_C: {
    url: URL.PAYMENT_OPTION_FORM_LOGIN_C,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  PAYMENT_OPTION_FORM_LOGIN_D: {
    url: URL.PAYMENT_OPTION_FORM_LOGIN_D,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  PAYMENT_OPTION_FORM_LOGIN_EMAIL: {
    url: URL.PAYMENT_OPTION_FORM_LOGIN_EMAIL,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  MP_PAYMENT_OPTION_FORM: {
    url: URL.MP_PAYMENT_OPTION_FORM,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  MP_NO_PAYMENT_OPTION_FORM: {
    url: URL.MP_NO_PAYMENT_OPTION_FORM,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  PAYMENT_UNAVAILABLE: {
    url: URL.PAYMENT_UNAVAILABLE,
    pageName: null,
    chunk: null,
  },
  CONGRATS_REJECTED: {
    url: URL.CONGRATS_REJECTED,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_APPROVED: {
    url: URL.CONGRATS_APPROVED,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_INSTRUCTIONS: {
    url: URL.CONGRATS_INSTRUCTIONS,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_CALL_FOR_AUTH: {
    url: URL.CONGRATS_RECOVER_CALL_FOR_AUTH,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_CALL_FOR_AUTH_AUTHORIZE: {
    url: URL.CONGRATS_RECOVER_CALL_FOR_AUTH_AUTHORIZE,
    pageName: PAGE_NAME.CONGRATS_RECOVER_FORM,
    chunk: CHUNK.CONGRATS_REVOVER_FORM,
    resolve: () => require.ensure([], (require) => require('../pages/congrats-recover-form'), 'chunk.congrats_recover_form'),
    webpack: () => require.resolveWeak('../pages/congrats-recover-form'),
  },
  CONGRATS_CALL_FOR_AUTH_LATER: {
    url: URL.CONGRATS_RECOVER_CALL_FOR_AUTH_LATER,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_RECOVER_ERROR: {
    url: URL.CONGRATS_RECOVER_ERROR,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_RECOVER_TIMEOUT: {
    url: URL.CONGRATS_RECOVER_TIMEOUT,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_RECOVER_DEFERRED_RETRY: {
    url: URL.CONGRATS_RECOVER_DEFERRED_RETRY,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_EXCHANGE_RATE_INVALID: {
    url: URL.CONGRATS_RECOVER_EXCHANGE_RATE_INVALID,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_EXPRESS_RECOVER_DEBIT_CARD: {
    url: URL.CONGRATS_RECOVER_EXPRESS,
    pageName: PAGE_NAME.EXPRESS,
    chunk: CHUNK.EXPRESS,
    resolve: () => require.ensure([], (require) => require('../pages/express'), 'chunk.express'),
    webpack: () => require.resolveWeak('../pages/express'),
  },
  CONGRATS_EXPRESS_RECOVER_CREDIT_CARD: {
    url: URL.CONGRATS_RECOVER_EXPRESS,
    pageName: PAGE_NAME.EXPRESS,
    chunk: CHUNK.EXPRESS,
    resolve: () => require.ensure([], (require) => require('../pages/express'), 'chunk.express'),
    webpack: () => require.resolveWeak('../pages/express'),
  },
  CONGRATS_EXPRESS_RECOVER_ACCOUNT_MONEY: {
    url: URL.CONGRATS_RECOVER_EXPRESS_ACCOUNT_MONEY,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  CONGRATS_EXPRESS_RECOVER_CONSUMER_CREDITS: {
    url: URL.CONGRATS_RECOVER_EXPRESS_CONSUMER_CREDITS,
    pageName: PAGE_NAME.CONSUMER_CREDITS,
    chunk: CHUNK.CREDITS,
    resolve: () => require.ensure([], (require) => require('../pages/consumer-credits'), 'chunk.credits'),
    webpack: () => require.resolveWeak('../pages/consumer-credits'),
  },

  WALLET_CARD_FORM: {
    url: URL.WALLET_CARD_FORM,
    pageName: PAGE_NAME.WALLET_CARD_FORM,
    chunk: CHUNK.WALLET_CARD_FORM,
    resolve: () => require.ensure([], (require) => require('../pages/wallet-card-form'), 'chunk.wallet_card_form'),
    webpack: () => require.resolveWeak('../pages/wallet-card-form'),
  },
  CONGRATS_CARD_DISABLED: {
    url: URL.CONGRATS_RECOVER_CARD_DISABLED,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  WALLET_CONGRATS_APPROVED: {
    url: URL.WALLET_CONGRATS_APPROVED,
    pageName: PAGE_NAME.WALLET_CONGRATS_APPROVED,
    chunk: CHUNK.WALLET_CONGRATS_APPROVED,
    disableAction: true,
    resolve: () => require.ensure([], (require) => require('../pages/wallet-congrats-approved'), 'chunk.wallet-congrats-approved'),
    webpack: () => require.resolveWeak('../pages/wallet-congrats-approved'),
  },
  WALLET_CONGRATS_REJECTED: {
    url: URL.WALLET_CONGRATS_REJECTED,
    pageName: PAGE_NAME.WALLET_CONGRATS_REJECTED,
    chunk: CHUNK.WALLET_CONGRATS_REJECTED,
    disableAction: null,
    resolve: () => require.ensure([], (require) => require('../pages/wallet-congrats-rejected'), 'chunk.wallet-congrats-rejected'),
    webpack: () => require.resolveWeak('../pages/wallet-congrats-rejected'),
  },
  CONGRATS_BAD_CARD_NUMBER: {
    url: URL.CONGRATS_RECOVER_BAD_CARD_NUMBER,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_BAD_OTHER: {
    url: URL.CONGRATS_RECOVER_BAD_OTHER,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_BAD_DATE: {
    url: URL.CONGRATS_RECOVER_BAD_DATE,
    pageName: PAGE_NAME.CONGRATS_RECOVER_FORM,
    chunk: CHUNK.CONGRATS_REVOVER_FORM,
    resolve: () => require.ensure([], (require) => require('../pages/congrats-recover-form'), 'chunk.congrats_recover_form'),
    webpack: () => require.resolveWeak('../pages/congrats-recover-form'),
  },
  CONGRATS_BAD_SECURITY_CODE: {
    url: URL.CONGRATS_RECOVER_BAD_SECURITY_CODE,
    pageName: PAGE_NAME.CONGRATS_RECOVER_FORM,
    chunk: CHUNK.CONGRATS_REVOVER_FORM,
    resolve: () => require.ensure([], (require) => require('../pages/congrats-recover-form'), 'chunk.congrats_recover_form'),
    webpack: () => require.resolveWeak('../pages/congrats-recover-form'),
  },
  CONGRATS_INVALID_INSTALLMENTS: {
    url: URL.CONGRATS_RECOVER_INVALID_INSTALLMENTS,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_INSUFFICIENT_AMOUNT: {
    url: URL.CONGRATS_RECOVER_INSUFFICIENT_AMOUNT,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  LOGIN_IRREVERSIBLE: {
    url: URL.LOGIN_WALLET,
    pageName: PAGE_NAME.WALLET_LOGIN,
    chunk: CHUNK.WALLET_LOGIN,
    resolve: () => require.ensure([], (require) => require('../pages/wallet-login'), 'chunk.wallet_login'),
    webpack: () => require.resolveWeak('../pages/wallet-login'),
  },
  CONGRATS_CONTENT_GROUP: {
    url: URL.CONGRATS_REMEDY,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_CONTINGENCY: {
    url: URL.CONGRATS_RECOVER_CONTINGENCY,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_REVIEW_MANUAL: {
    url: URL.CONGRATS_RECOVER_REVIEW_MANUAL,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  CONGRATS_REMEDY_OFFER: {
    url: URL.CONGRATS_RECOVER_REMEDY_OFFER,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  ACCOUNT_MONEY_SECOND_FACTOR: {
    url: URL.ACCOUNT_MONEY_SECOND_FACTOR,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  FIRST_SPLIT_PAYMENT: {
    url: URL.FIRST_SPLIT_PAYMENT,
    pageName: PAGE_NAME.SPLIT_PAYMENT,
    chunk: CHUNK.SPLIT_PAYMENT,
    resolve: () => require.ensure([], (require) => require('../pages/split-payment'), 'chunk.split_payment'),
    webpack: () => require.resolveWeak('../pages/split-payment'),
  },
  SECOND_SPLIT_PAYMENT: {
    url: URL.SECOND_SPLIT_PAYMENT,
    pageName: PAGE_NAME.SPLIT_PAYMENT,
    chunk: CHUNK.SPLIT_PAYMENT,
    resolve: () => require.ensure([], (require) => require('../pages/split-payment'), 'chunk.split_payment'),
    webpack: () => require.resolveWeak('../pages/split-payment'),
  },
  WALLET_PAYMENT: {
    url: URL.WALLET,
    pageName: PAGE_NAME.WALLET_PAYMENT,
    chunk: CHUNK.WALLET_PAYMENT,
    resolve: () => require.ensure([], (require) => require('../pages/wallet-payment'), 'chunk.wallet_payment'),
    webpack: () => require.resolveWeak('../pages/wallet-payment'),
  },
  SHIPPING_OPTIONS: {
    url: URL.SHIPPING_OPTIONS,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  SHIPPING_REVIEW: {
    url: URL.SHIPPING_REVIEW,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  CONGRATS_REDIRECT: {
    url: URL.CONGRATS_REDIRECT,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  ONECLICK_REDIRECT: {
    url: URL.ONECLICK_REDIRECT,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  ZIPCODE_FORM: {
    url: URL.SHIPPING_ZIPCODE_FORM,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  ADDRESS_FORM: {
    url: URL.SHIPPING_ADDRESS_FORM,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  USER_TYPE: {
    url: URL.SHIPPING_USER_TYPE,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  SHIPPING_TYPE: {
    url: URL.SHIPPING_TYPE,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  ADDRESS_SELECTOR: {
    url: URL.SHIPPING_ADDRESS_SELECTOR,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  CHALLENGE_3DS: {
    url: URL.CHALLENGE_3DS,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  AGENCIES_MAP: {
    url: URL.AGENCIES_MAP,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  CONSUMER_CREDITS_TYC: {
    url: URL.CONSUMER_CREDITS_TYC,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  OPENSEA_CREDITS_FIRST_USE: {
    url: URL.OPENSEA_CREDITS_FIRST_USE,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  OPENSEA_CREDITS_LOADER: {
    url: URL.OPENSEA_CREDITS_REDIRECT,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  OPENSEA_CREDITS_RETRY: {
    url: URL.OPENSEA_CREDITS_RETRY,
    pageName: PAGE_NAME.SPINNER,
    chunk: CHUNK.SPINNER,
    resolve: () => require.ensure([], (require) => require('../pages/spinner'), 'chunk.spinner'),
    webpack: () => require.resolveWeak('../pages/spinner'),
  },
  TRACKING_REDIRECT: {
    url: URL.TRACKING_REDIRECT,
    pageName: PAGE_NAME.TRACKING_REDIRECT,
    disableAction: true,
    chunk: CHUNK.TRACKING_REDIRECT,
    resolve: () => require.ensure([], (require) => require('../pages/tracking-redirect'), 'chunk.tracking_redirect'),
    webpack: () => require.resolveWeak('../pages/tracking-redirect'),
  },
  REVERSIBLE_ERROR: {
    url: URL.REVERSIBLE_ERROR,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  SNIFFING_SCAN_QR: {
    url: URL.SNIFFING_SCAN_QR,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  OPEN_FINANCE_REDIRECT: {
    url: URL.OPENFINANCE_REDIRECT,
    pageName: PAGE_NAME.OPENFINANCE_REDIRECT,
    chunk: CHUNK.OPENFINANCE,
    resolve: () => require.ensure([], (require) => require('../pages/openfinance-redirect'), 'chunk.open-finance'),
    webpack: () => require.resolveWeak('../pages/openfinance-redirect'),
  },
  OPEN_FINANCE_AWAITING: {
    url: URL.OPENFINANCE_AWAITING,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    disableAction: null,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  CONGRATS_OPEN_FINANCE_PENDING: {
    url: URL.OPENFINANCE_PENDING,
    pageName: PAGE_NAME.CONGRATS,
    chunk: CHUNK.CONGRATS,
    resolve: () => require.ensure([], (require) => require('../pages/congrats'), 'chunk.congrats'),
    webpack: () => require.resolveWeak('../pages/congrats'),
  },
  OPEN_FINANCE_BANK_SELECTION: {
    url: URL.OPENFINANCE_BANK_SELECTION,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  CHALLENGE_3DS_REMEDY: {
    url: URL.CHALLENGE_3DS_REMEDY,
    pageName: PAGE_NAME.CHALLENGE_3DS_REMEDY,
    chunk: CHUNK.CHALLENGE_3DS_REMEDY,
    resolve: () => require.ensure([], (require) => require('../pages/challenge-3ds-remedy'), 'chunk.challenge-3ds-remedy'),
    webpack: () => require.resolveWeak('../pages/challenge-3ds-remedy'),
  },
  UNICO_CHALLENGE_REMEDY: {
    url: URL.UNICO_CHALLENGE_REMEDY,
    pageName: PAGE_NAME.UNICO_CHALLENGE_REMEDY,
    chunk: CHUNK.UNICO_CHALLENGE_REMEDY,
    resolve: () => require.ensure([], (require) => require('../pages/unico-challenge-remedy'), 'chunk.unico-challenge-remedy'),
    webpack: () => require.resolveWeak('../pages/unico-challenge-remedy'),
  },
  CHALLENGE_RANDOM_CHARGE_REMEDY: {
    url: URL.CHALLENGE_RANDOM_CHARGE_REMEDY,
    pageName: PAGE_NAME.CHALLENGE_RANDOM_CHARGE_REMEDY,
    chunk: CHUNK.CHALLENGE_RANDOM_CHARGE_REMEDY,
    resolve: () =>
      require.ensure(
        [],
        (require) => require('../pages/challenge-random-charge-remedy'),
        'chunk.challenge-random-charge-remedy',
      ),
    webpack: () => require.resolveWeak('../pages/challenge-random-charge-remedy'),
  },
  NOT_FOUND_ERROR: {
    url: URL.NOT_FOUND_ERROR,
    pageName: PAGE_NAME.NOT_FOUND,
    chunk: CHUNK.NOT_FOUND_ERROR,
    resolve: () => require.ensure([], (require) => require('../pages/not-found'), 'chunk.not_found_error'),
    webpack: () => require.resolveWeak('../pages/not-found'),
  },
  WEBVIEW_SUPPORT: {
    url: URL.WEBVIEW_NOT_SUPPORTED,
    pageName: PAGE_NAME.WEBVIEW_NOT_SUPPORTED,
    chunk: CHUNK.WEBVIEW_NOT_SUPPORTED,
    disableAction: true,
    resolve: () => require.ensure([], (require) => require('../pages/webview-support'), 'chunk.webview-support'),
    webpack: () => require.resolveWeak('../pages/webview-support'),
  },
  CHALLENGE_FACETEC_REMEDY: {
    url: URL.CHALLENGE_FACETEC_REMEDY,
    pageName: PAGE_NAME.CHALLENGE_FACETEC_REMEDY,
    chunk: CHUNK.CHALLENGE_FACETEC_REMEDY,
    resolve: () => require.ensure([], (require) => require('../pages/challenge-facetec-remedy'), 'chunk.challenge-facetec-remedy'),
    webpack: () => require.resolveWeak('../pages/challenge-facetec-remedy'),
  },
  COMBINED_PAYMENT_AMOUNT_FORM: {
    url: URL.COMBINED_PAYMENT_AMOUNT_FORM,
    pageName: PAGE_NAME.COMBINED_PAYMENT_AMOUNT_FORM,
    chunk: CHUNK.COMBINED_PAYMENT_AMOUNT_FORM,
    resolve: () =>
      require.ensure(
        [],
        (require) => require('../pages/combined-payment-amount-form'),
        'chunk.combined-payment-amount-form',
      ),
    webpack: () => require.resolveWeak('../pages/combined-payment-amount-form'),
  },
  COMBINED_PAYMENT_REVIEW: {
    url: URL.COMBINED_PAYMENT_REVIEW,
    pageName: PAGE_NAME.GENERIC,
    chunk: CHUNK.GENERIC,
    resolve: () => require.ensure([], (require) => require('../pages/generic'), 'chunk.generic'),
    webpack: () => require.resolveWeak('../pages/generic'),
  },
  PERSONAL_INFO_FORM_STEP: {
    url: URL.PERSONAL_INFO_FORM_STEP,
    pageName: PAGE_NAME.PERSONAL_INFO_FORM_STEP,
    chunk: CHUNK.PERSONAL_INFO_FORM_STEP,
    resolve: () => require.ensure([], (require) => require('../pages/personal-info-form'), 'chunk.personal-info-form-step'),
    webpack: () => require.resolveWeak('../pages/personal-info-form'),
  },
  PERSONAL_INFO_FORM_STEP_PSE: {
    url: URL.PERSONAL_INFO_FORM_STEP_PSE,
    pageName: PAGE_NAME.PERSONAL_INFO_FORM_STEP_PSE,
    chunk: CHUNK.PERSONAL_INFO_FORM_STEP_PSE,
    resolve: () => require.ensure([], (require) => require('../pages/personal-info-form'), 'chunk.personal-info-form-step'),
    webpack: () => require.resolveWeak('../pages/personal-info-form'),
  },
  ADDRESS_INFO_FORM_STEP: {
    url: URL.ADDRESS_INFO_FORM_STEP,
    pageName: PAGE_NAME.ADDRESS_INFO_FORM_STEP,
    chunk: CHUNK.ADDRESS_INFO_FORM_STEP,
    resolve: () => require.ensure([], (require) => require('../pages/address-info-form'), 'chunk.address-info-form-step'),
    webpack: () => require.resolveWeak('../pages/address-info-form'),
  },
  TERMS_AND_CONDITIONS: {
    url: URL.TERMS_AND_CONDITIONS,
    pageName: PAGE_NAME.TERMS_AND_CONDITIONS,
    chunk: CHUNK.TERMS_AND_CONDITIONS,
    resolve: () => require.ensure([], (require) => require('../pages/terms-and-conditions'), 'chunk.terms-and-conditions'),
    webpack: () => require.resolveWeak('../pages/terms-and-conditions'),
  },
};

const routesMap = {};

Object.keys(steps).forEach((it) => {
  const key = steps[it].chunk;

  if (key) {
    if (!routesMap[key]) {
      routesMap[key] = {
        steps: [],
      };
    }

    routesMap[key].steps.push(steps[it].url);

    if (!routesMap[key].chunk) {
      routesMap[key].chunk = steps[it].chunk;
      routesMap[key].resolve = steps[it].resolve;
      routesMap[key].webpack = steps[it].webpack;
      routesMap[key].disableAction = steps[it].disableAction;
    }
  }
});

module.exports = {
  steps,
  routesMap,
  /**
   * Get step from name
   * @param name
   * @returns {*}
   */
  getStep: (name = '') => steps[name.toUpperCase()],

  /**
   * Get the name of the step from url path name
   * @param name
   * @returns {*}
   */
  getStepFromUrl: (name) => {
    let step;

    Object.keys(steps).forEach((key) => {
      if (steps[key].url === name) {
        step = key.toLowerCase();
        return; // eslint-disable-line
      }
    });

    return step;
  },
};
