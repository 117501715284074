import type { ButtonProps } from '@andes/button';

import React, { useEffect, useState } from 'react';
import { Modal } from '@andes/modal';
import { Typography } from '@andes/typography';
import { Button } from '@andes/button';

import { DeviceType } from '../../constants/commons';

export type KycModalV2Props = {
  image: string;
  altImageText?: string;
  title: string;
  description: string;
  submitLabel: string;
  alternativeOptionLabel: string;
  deviceType?: DeviceType.DESKTOP | DeviceType.MOBILE;
  isOpen?: boolean;
  showClose?: boolean;
  className?: string;
  maxWidth?: number;
  dispatcherSelector?: string;
  onClose?: () => void;
  onOpen?: () => void;
  onSubmit: () => void;
  onSubmitAlternativeOption: ButtonProps['onClick'];
};

const KycModalV2 = ({
  deviceType = DeviceType.DESKTOP,
  className = '',
  image,
  altImageText = 'Kyc image',
  title,
  description,
  submitLabel,
  alternativeOptionLabel,
  isOpen = false,
  maxWidth = 500,
  onSubmit,
  onSubmitAlternativeOption,
  onClose,
  onOpen,
  dispatcherSelector,
}: KycModalV2Props) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  const handleClose = () => {
    setIsModalOpen(false);
    onClose?.();
  };

  const handleOpen = () => {
    setIsModalOpen(true);
    onOpen?.();
  };

  function handleDispatcher(event: MouseEvent) {
    event.preventDefault();
    setIsModalOpen(true);
  }

  useEffect(() => {
    if (!dispatcherSelector) {
      return;
    }

    const dispatcherElem = document.querySelector(
      dispatcherSelector,
    ) as HTMLButtonElement;

    if (!dispatcherElem) {
      console.error('Invalid Dispatcher ID (KycModalV2 Component)');
      return;
    }

    dispatcherElem.addEventListener('click', handleDispatcher);

    return () => {
      dispatcherElem.removeEventListener('click', handleDispatcher);
    };
  }, [dispatcherSelector]);

  return (
    <React.Fragment>
      <Modal
        title=""
        className={`kyc-modal ${className}`}
        closable={false}
        open={isModalOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        type={deviceType === DeviceType.MOBILE ? 'card' : 'small'}
        maxWidth={maxWidth}
      >
        <div className="kyc-modal__content">
          <figure className="kyc-modal__content__image">
            <img src={image} alt={altImageText} />
          </figure>

          <div className="kyc-modal__content__title">
            <Typography
              className="title__text"
              weight="regular"
              type="title"
              size="s"
            >
              {title}
            </Typography>
          </div>

          <div className="kyc-modal__content__description">
            <Typography className="description__text" type="body" size="m">
              {description}
            </Typography>
          </div>

          <div className="kyc-modal__content__button-container">
            <Button
              className="kyc-modal__content__button-container__submit"
              onClick={onSubmit}
              size="large"
            >
              {submitLabel}
            </Button>

            <Button
              className="kyc-modal__content__button-container__alternative"
              onClick={onSubmitAlternativeOption}
              size="large"
              hierarchy="transparent"
            >
              {alternativeOptionLabel}
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default KycModalV2;
