import type { AccordionIndicatorProps } from '@andes/accordion';
import type { PaymentSummaryItem } from '../PaymentSummary/types';

import React from 'react';
import classNames from 'classnames';
import { Accordion } from '@andes/accordion';
import { List, ListItem } from '@andes/list';
import { Text } from '@andes/typography';
import { Tooltip } from '@andes/tooltip';

import Info from '../Icons/info';

export type RowCollapsibleProps = PaymentSummaryItem & {
  isMobile?: boolean;
  blockExpand?: boolean;
};

function RowCollapsible(props: RowCollapsibleProps) {
  const [tooltipOpened, setTooltipOpened] = React.useState<boolean>(false);

  const [expanded, setExpanded] = React.useState<boolean>(
    props.blockExpand || false,
  );

  const indicator = React.useMemo(
    (): AccordionIndicatorProps | undefined =>
      props.blockExpand
        ? {
            collapsedLabel: '',
            expandedLabel: '',
            showIcon: false,
          }
        : undefined,
    [props.blockExpand],
  );

  const onChange = React.useCallback(() => {
    if (props.blockExpand) {
      return;
    }

    setExpanded((previousState) => !previousState);
  }, [props.blockExpand]);

  return (
    <Accordion
      className={classNames(`cow_row_collapsible`, {
        'cow_row_collapsible--block-expand': props.blockExpand,
      })}
      showDivider={false}
      expanded={expanded}
      indicator={indicator}
      onChange={onChange}
      title={
        <List>
          <ListItem
            title={
              <Text component="p" size={props.isMobile ? 's' : 'm'}>
                {props.name}
              </Text>
            }
            rightContent={
              <Text component="p" size={props.isMobile ? 's' : 'm'}>
                {props.price}
              </Text>
            }
          />
        </List>
      }
    >
      <List>
        {props.subItems?.map((subItem, index) => (
          <ListItem
            key={index}
            description={
              <div className="cow_row_collapsible__subitem_label">
                <Text component="p" size="s" color="secondary">
                  {subItem.label}
                </Text>

                {subItem.tooltipContent && (
                  <Tooltip
                    content={subItem.tooltipContent}
                    type="dark"
                    className="cow_row_collapsible__subitem_label__tooltip"
                    open={props.isMobile ? tooltipOpened : undefined}
                    closable={props.isMobile}
                    onClose={(_event) => setTooltipOpened(false)}
                  >
                    <Info
                      width={16}
                      height={16}
                      className="cow_row_collapsible__subitem_label__info"
                      onMouseEnter={(_event) => {
                        if (!props.isMobile) {
                          return;
                        }

                        setTooltipOpened(true);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            }
            rightContent={
              <Text component="p" size="s" color="secondary">
                {subItem.price}
              </Text>
            }
          />
        ))}
      </List>
    </Accordion>
  );
}

export default React.memo(RowCollapsible);
