/*
  Steps that use this file:
  - review
  - review-express
  - card-form
  - installments-form
*/

// Andes components
const { Tooltip } = require('@andes/tooltip');

// Custom components
const QuestionMark = require('../../components/icons/GenericQuestionMark');

// Constants
const {
  COLORS,
  APP: {
    SUMMARY_ITEMS_TYPES: { TAX, PREF_DISCOUNT, COUPON_DISCOUNT, PRODUCT },
  },
  COMMONS: {
    DEVICE_TYPE,
    ANDES_PLACEMENT,
    CHECKOUT_CASE: { PAYMENT, SUBSCRIPTION, EXPRESS },
  },
} = require('../../../constants');

const PAYMENT_SUMMARY_ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
};

const getValidSideOfTooltip = ({ deviceType, tooltipContainer }) => {
  const maxSpaceThatBreakTooltip = 65;

  if (deviceType === DEVICE_TYPE.DESKTOP) {
    return ANDES_PLACEMENT.BOTTOM_LEFT;
  }

  if (tooltipContainer.current && tooltipContainer.current.offsetLeft > maxSpaceThatBreakTooltip) {
    return ANDES_PLACEMENT.BOTTOM;
  }

  return ANDES_PLACEMENT.TOP;
};

const DEFAULT_CENTS = 20;
const MOBILE_CENTS = 14;
const DESKTOP_CENTS = 16;

const handleSummaryCents = (cents, deviceType) => {
  if (cents?.length) {
    return DEFAULT_CENTS;
  }

  if (deviceType === DEVICE_TYPE.MOBILE) {
    return MOBILE_CENTS;
  }

  return DESKTOP_CENTS;
};

const getCouponDetail = (detail, deviceType, isSubscription) => {
  if (isSubscription) {
    return '';
  }

  if (detail && deviceType === DEVICE_TYPE.MOBILE) {
    return detail;
  }

  return '';
};

const getCouponDiscountName = (name, deviceType, translations, isSubscription) => {
  if (!name) {
    return translations.DISCOUNT;
  }

  const getMobileName = () => {
    if (isSubscription) {
      return `${translations.DISCOUNT} ${name}`;
    }

    return `${name} ${translations.OFF}`;
  };

  const getDesktopName = () => `${translations.DISCOUNT} ${name}`;

  const containPercentage = name.includes('%');

  if (containPercentage) {
    return deviceType === DEVICE_TYPE.MOBILE ? getMobileName() : getDesktopName();
  }

  return name;
};

const handleSummaryItemProps = ({
  item,
  summary,
  translations,
  deviceType,
  tooltipContainer,
  setShowDiscountModal,
  iconAlignment,
  isSubscription,
}) => {
  if (!item) {
    return {};
  }

  const { type, name, price, detail, subItems } = item;
  const priceSize = handleSummaryCents(summary?.total?.price?.cents, deviceType);

  const baseProps = {
    name,
    price: price?.text_value,
    priceCents: price?.cents,
    priceSize,
    type,
  };

  const getIconForType = (type) => {
    switch (type) {
      case COUPON_DISCOUNT:
        return <QuestionMark onClick={() => setShowDiscountModal(true)} />;

      case TAX:
        return (
          <Tooltip
            ref={tooltipContainer}
            className="icon-with-tooltip__content"
            side={getValidSideOfTooltip({ deviceType, tooltipContainer })}
            content={detail}
            mouseLeaveDelay={deviceType === DEVICE_TYPE.MOBILE ? 0 : 2}
          >
            <QuestionMark />
          </Tooltip>
        );

      default:
        return null;
    }
  };

  switch (type) {
    case COUPON_DISCOUNT:
      return {
        ...baseProps,
        nameColor: COLORS.POSITIVE,
        priceColor: isSubscription ? COLORS.POSITIVE : COLORS.PRIMARY,
        icon: getIconForType(type),
        iconAlignment,
        name: getCouponDiscountName(baseProps.name, deviceType, translations, isSubscription),
        nameComplement: getCouponDetail(detail, deviceType, isSubscription),
      };

    case PREF_DISCOUNT:
      return {
        ...baseProps,
        nameColor: COLORS.POSITIVE,
        priceColor: COLORS.POSITIVE,
        name: getCouponDiscountName(baseProps.name, deviceType, translations, isSubscription),
        nameComplement: getCouponDetail(detail, deviceType, isSubscription),
      };

    case TAX:
      return {
        ...baseProps,
        nameColor: COLORS.PRIMARY,
        priceColor: COLORS.PRIMARY,
        icon: getIconForType(type),
        priceSize: deviceType === DEVICE_TYPE.MOBILE ? 14 : 16,
      };

    case PRODUCT:
      return {
        ...baseProps,
        nameColor: COLORS.PRIMARY,
        priceColor: price?.text_value === '' ? COLORS.POSITIVE : COLORS.PRIMARY,
        price: price?.text_value === '' ? translations.FREE : price?.text_value,
        subItems,
      };

    default:
      return {
        ...baseProps,
        nameColor: COLORS.PRIMARY,
        priceColor: price?.text_value === '' ? COLORS.POSITIVE : COLORS.PRIMARY,
        price: price?.text_value === '' ? translations.FREE : price?.text_value,
      };
  }
};

// Summary mobile functions
const isCollapsible = (itemsLength, transactionType, summary) => {
  const allowedTransactionType = [PAYMENT, EXPRESS];
  return (
    (itemsLength > 1 && allowedTransactionType.includes(transactionType)) ||
    summary?.items?.some?.((item) => item.subItems)
  );
};

const getMobileTotal = (summary, transactionType, subscription, customPrice = null) => {
  if (isCollapsible(summary?.items?.length, transactionType, summary) || transactionType === SUBSCRIPTION) {
    return {
      name: summary.total.name,
      price: customPrice || summary.total.price.text_value,
      priceSize: handleSummaryCents(summary?.total?.price?.cents, DEVICE_TYPE.MOBILE),
      priceCents: summary?.total?.price?.cents,
      nameObservation: subscription?.next_invoice ?? '',
      nameObservationColor: 'secondary',
      nameObservationSize: 's',
      nameWeight: summary?.total?.nameWeight || 'regular',
      priceObservation: summary?.total?.detail,
      priceObservationColor: summary?.total?.detailColor || 'positive',
      observationDirection: summary?.total?.detailDirection || 'column',
      priceWeight: summary?.total?.priceWeight || 'regular',
    };
  }

  return {
    name: summary?.items?.[0]?.name,
    price: customPrice || summary.total.price.text_value,
    priceSize: handleSummaryCents(summary?.total?.price?.cents, DEVICE_TYPE.MOBILE),
    priceCents: summary?.total?.price?.cents,
  };
};

const hasBrand = (brand) => Boolean(brand && brand.name && brand.avatar);

module.exports = {
  hasBrand,
  isCollapsible,
  getMobileTotal,
  handleSummaryItemProps,
  PAYMENT_SUMMARY_ALIGNMENT,
};
