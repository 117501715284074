/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

// Redux
const { connect } = require('react-redux');
const { bindActionCreators } = require('redux');

// i18n
const { injectI18n } = require('nordic/i18n');
const translate = require('../../../../../translation');

// Andes components
const { Message } = require('@andes/message');
const { CardFooter } = require('@andes/card');

// Cow components
const PaymentMethodCardList = require('@cow/core-components/components/PaymentMethodCardList');

// Custom Components
const ReviewBase = require('../index');
const ConsumerCredits = require('../../components/ConsumerCredits');

// Custom hooks
const { useActions } = require('../../hooks/useActions');
const { usePaymentCardList } = require('../../hooks/usePaymentCardList');

// Actions
const STEP_ACTIONS = require('../../../../../spa/actions/step');
const REQUEST_ACTIONS = require('../../../../../spa/actions/request');
const LOADING_ACTIONS = require('../../../../../spa/actions/loading');
const EXPRESS_ACTIONS = require('../../../../../spa/actions/express');
const captchaToken = require('../../../../../spa/actions/captchaToken');

// Action Types
const {
  LOADER_PAY_BUTTON,
  EXPRESS_RESET_CARD,
  EXPRESS_CARD_TOKEN,
  EXPRESS_RESET_INSTALLMENTS,
  EXPRESS_SET_INSTALLMENTS_ERROR,
  EXPRESS_SET_SECURE_FIELDS_ERROR,
  EXPRESS_SET_SECURE_FIELDS_FOCUS,
  EXPRESS_RESET_SECURE_FIELDS,
} = require('../../../../../spa/actions/types');

// Services
const {
  sendReviewExpressPayMetric,
  sendReviewExpressLoadMetric,
  sendReviewExpressChangedMetric,
} = require('../../../../../service/metrics');

// Utils
const PCJ = require('../../../../../utils/pcj');
const { sleep } = require('../../../../../utils/sleep');
const { setCookie } = require('../../../../../utils/CookieUtils');
const { formatAmountWithInstallments } = require('../../../../../utils/format');
const { localeFromSiteId } = require('../../../../../utils/locale');
const {
  createCardToken,
  mountSecureField,
  unmountSecureField,
  remountSecureField,
  createCardTokenWithEsc,
} = require('../../utils/secure-fields');
const {
  genericPropsTypes,
  genericDefaultValues,
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
} = require('../../../../../utils/propTypes');

// Constants
const {
  APP: {
    COOKIES,
    SUMMARY_ITEMS_TYPES: { COUPON_DISCOUNT },
    SECURE_FIELDS: {
      ERROR_CAUSES: { INVALID_VALUE },
    },
  },
  COMMONS: {
    SITE_ID,
    DEVICE_TYPE: { DESKTOP },
    CHECKOUT_CASE: { EXPRESS },
    PAYMENT_METHOD_ID: { CONSUMER_CREDITS },
    PAYMENT_METHOD: { CREDIT_CARD, DEBIT_CARD },
  },
  SELECTORS: { ANDES_BUTTON_PAY_DESKTOP },
} = require('../../../../../../constants');

const ReviewExpress = (props) => {
  const {
    sdk,
    flow,
    i18n,
    siteId,
    history,
    express,
    stepActions,
    captchaToken,
    captchaConfig,
    expressActions,
    requestActions,
    loadingActions,
    internalDeviceId,
  } = props;

  const { payer, summary, captcha, payment_methods, challenge_instructions: challengesInstructions } = props.step_model;

  const translations = translate(i18n);
  const { locale, currency } = localeFromSiteId(siteId);

  const [cardToken, setCardToken] = React.useState('');
  const [nextDueDate, setNextDueDate] = React.useState(null);
  const [showKycModal, setShowKycModal] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState(payment_methods);
  const [paymentMethod, setPaymentMethod] = React.useState(payment_methods[0]);
  const [showConsumerCreditsInfos, setShowConsumerCreditsInfos] = React.useState(false);

  const { paymentCardList, shouldRenderInstallments, shouldRenderSecurityCode } = usePaymentCardList({
    express,
    translations,
    paymentMethods,
    discount: paymentMethod?.discount,
  });

  const { changePaymentMethod, reviewExpressPay, reviewExpressKyC } = useActions({
    flow,
    payer,
    captcha,
    history,
    stepActions,
    captchaToken,
    captchaConfig,
    requestActions,
    loadingActions,
    challengesInstructions,
  });

  const secureFieldActions = {
    resetSecureField: expressActions[EXPRESS_RESET_SECURE_FIELDS],
    setSecureFieldError: expressActions[EXPRESS_SET_SECURE_FIELDS_ERROR],
    setSecureFieldFocus: expressActions[EXPRESS_SET_SECURE_FIELDS_FOCUS],
  };

  const getBottomContentListClass = () => {
    const lastPaymentMethod = paymentCardList[paymentCardList.length - 1];
    return classNames('review-express__change-payment-method', {
      'review-express__change-payment-method--single': !lastPaymentMethod.collapsedContent,
    });
  };

  const showBankInterests = () => {
    const sitesWithBankInterests = [SITE_ID.MLC, SITE_ID.MPE, SITE_ID.MCO];
    return sitesWithBankInterests.includes(siteId) && paymentMethod?.type === CREDIT_CARD;
  };

  const getCustomSummaryItems = () => {
    const customSummaryItems = summary?.items?.filter((item) => item.type !== COUPON_DISCOUNT);
    return [...customSummaryItems, paymentMethod?.discount];
  };

  const handleConsumerCreditsChange = (paymentMethod) => {
    if (paymentMethod.id === CONSUMER_CREDITS) {
      setNextDueDate(paymentMethod.next_due_date);
      setShowConsumerCreditsInfos(true);
      return;
    }

    setNextDueDate(null);
    setShowConsumerCreditsInfos(false);
  };

  const handleSecureFieldChange = (paymentMethod) => {
    remountSecureField(paymentMethod, secureFieldActions);
  };

  const handleInstallmentsChange = () => {
    expressActions[EXPRESS_RESET_INSTALLMENTS](paymentMethod.option_id);
  };

  const handlePaymentMethodChange = (paymentMethodId) => {
    const findPaymentMethod = paymentMethods?.find((paymentMethod) => paymentMethod.option_id === paymentMethodId);

    if (paymentMethodId === paymentMethod.option_id) {
      return;
    }

    handleInstallmentsChange();

    handleSecureFieldChange(findPaymentMethod);
    handleConsumerCreditsChange(findPaymentMethod);
    setPaymentMethod(findPaymentMethod);

    setShowKycModal(false);
  };

  const handleFieldsValidation = async (securityCodeCardToken) => {
    let isSecurityCodeValid = true;
    let isInstallmentsValid = true;

    const renderInstallments = shouldRenderInstallments(paymentMethod);
    const renderSecurityCode = shouldRenderSecurityCode(paymentMethod);

    if (!renderSecurityCode && !renderInstallments) {
      return true;
    }

    if (renderSecurityCode && !securityCodeCardToken) {
      const { error } = await createCardToken();

      if (error) {
        isSecurityCodeValid = false;
        secureFieldActions.setSecureFieldError(
          Array.isArray(error) && error.find((err) => err.cause === INVALID_VALUE)
            ? translations.CVV_IS_REQUIRED
            : translations.CVV_INCOMPLETE,
        );
      }
    }

    if (renderInstallments) {
      isInstallmentsValid = express?.installments?.[paymentMethod.option_id]?.value;
      expressActions[EXPRESS_SET_INSTALLMENTS_ERROR](paymentMethod.option_id, !isInstallmentsValid);
    }

    return isInstallmentsValid && isSecurityCodeValid;
  };

  const handleChangePaymentMethod = () => {
    sendReviewExpressChangedMetric({ device: DESKTOP });
    unmountSecureField();
    changePaymentMethod();
  };

  const handleSubmit = ({ action, cardTokenId, installments }) => {
    const payload = {
      card_token: cardTokenId,
      installments: String(installments),
      payer: {
        email: payer?.email?.value,
      },
      payment_method: {
        type: paymentMethod?.type,
        method: paymentMethod?.id,
        id: paymentMethod?.option_id,
      },
    };

    unmountSecureField();
    action(payload);
  };

  const handleKyc = () => {
    const cardId = paymentMethod?.option_id;
    const cardTokenId = expressActions[EXPRESS_CARD_TOKEN](cardId) || cardToken;
    const installments = express?.installments?.[cardId]?.value || 1;
    handleSubmit({ action: reviewExpressKyC, cardTokenId, installments });
  };

  const handlePayClick = async () => {
    const cardId = paymentMethod?.option_id;
    const installments = express?.installments?.[cardId]?.value || 1;
    let cardTokenId = expressActions[EXPRESS_CARD_TOKEN](cardId) || cardToken;

    if (!(await handleFieldsValidation(cardTokenId))) {
      setShowKycModal(false);
      return;
    }

    if (!cardTokenId && (paymentMethod.type === CREDIT_CARD || paymentMethod.type === DEBIT_CARD)) {
      loadingActions[LOADER_PAY_BUTTON](true);

      const { error, response } = await createCardTokenWithEsc(cardId, internalDeviceId);

      if (error) {
        loadingActions[LOADER_PAY_BUTTON](false);
        secureFieldActions.setSecureFieldError(translations.INVALID_CVV);
        return;
      }

      cardTokenId = response.id;

      if (response.esc) {
        setCookie(`${COOKIES.ESC}${cardId}`, response.esc);
      }

      setCardToken(cardTokenId);
      expressActions[EXPRESS_RESET_CARD](cardId);
    }

    setShowKycModal(true);

    if (challengesInstructions?.kyc) {
      if (!showKycModal) {
        sleep().then(() => {
          document.querySelector(ANDES_BUTTON_PAY_DESKTOP)?.click();
        });
      }

      loadingActions[LOADER_PAY_BUTTON](false);
      return;
    }

    sendReviewExpressPayMetric({ device: DESKTOP });

    handleSubmit({ action: reviewExpressPay, cardTokenId, installments });
  };

  React.useEffect(() => {
    sendReviewExpressLoadMetric({ device: DESKTOP });

    if (window && window?.hj) {
      window.hj('event', 'review_express_redesigned');
    }
  }, []);

  React.useEffect(() => {
    const pms = props.step_model.payment_methods;
    const pm = pms?.find((pm) => pm.option_id === paymentMethod.option_id);
    setPaymentMethods(pms);
    setPaymentMethod(pm);
  }, [props.step_model.payment_methods]);

  React.useEffect(() => {
    if (sdk.loaded) {
      mountSecureField(paymentMethod, secureFieldActions);
    }
  }, [sdk.loaded]);

  return (
    <ReviewBase
      deviceType={DESKTOP}
      templateCase={EXPRESS}
      showIncentives={false}
      showKycModal={showKycModal}
      customHandleKyc={handleKyc}
      customSummaryItems={getCustomSummaryItems()}
      customHandlePayClick={handlePayClick}
      customPrice={
        express?.installments?.[paymentMethod.option_id]?.price ||
        formatAmountWithInstallments(siteId, locale, currency, paymentMethod?.total_amount)
      }
      customFooterMessage={
        showBankInterests() && (
          <Message hierarchy="quiet" color="accent" className="review-express__bank_interests">
            {translations.BANK_INTERESTS}
          </Message>
        )
      }
      consumerCreditsFooter={
        showConsumerCreditsInfos && (
          <ConsumerCredits className="review-express__consumer-credits__footer">
            <ConsumerCredits.Legals siteId={siteId} />
          </ConsumerCredits>
        )
      }
      consumerCreditsSummary={
        showConsumerCreditsInfos && (
          <ConsumerCredits className="review-express__consumer-credits__summary">
            <ConsumerCredits.NextDueDate date={nextDueDate} siteId={siteId} />
            <ConsumerCredits.TermsAndConditions
              siteId={siteId}
              deviceType={DESKTOP}
              contractUrl={express?.installments?.[paymentMethod.option_id]?.contract}
            />
          </ConsumerCredits>
        )
      }
      customCardList={
        <PaymentMethodCardList
          withDividers
          deviceType={DESKTOP}
          items={paymentCardList}
          currentSelected={paymentMethod?.option_id}
          onChange={(value) => handlePaymentMethodChange(value)}
          bottomContentList={
            <CardFooter
              className={getBottomContentListClass()}
              link={{
                icon: true,
                text: translations.CHANGE_PAYMENT_METHOD,
                onClick: handleChangePaymentMethod,
              }}
            />
          }
        />
      }
      {...PCJ.ifCandidateInjectPropsIntoSummary({ props, paymentMethod, translations })}
    />
  );
};

ReviewExpress.defaultProps = {
  ...genericDefaultValues,
  step_model: {
    ...newInterfaceGenericDefaultValues,
  },
};

ReviewExpress.propTypes = {
  ...genericPropsTypes,
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
};

// Map all the actions with the dispatchers on the props
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  expressActions: bindActionCreators(EXPRESS_ACTIONS, dispatch),
  captchaToken: bindActionCreators(captchaToken, dispatch),
});

// Generate the state (store) using the reducers
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  sdk: state.mercadopagoSdk,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  siteId: state.configurations.platform.siteId,
  captchaConfig: state.configurations.captcha,
  express: state.express,
  currency: state.configurations.currency,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = ReviewExpress;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(ReviewExpress));
}
