/* global window, document */
const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');
const classNames = require('classnames');

const { bindEventListener } = require('../../../utils/Dom');
const {
  CURRENT_STEP: { PAYMENT_OPTION_FORM_COMBINATION },
} = require('../../../../constants/app');

class StickyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.state = {
      isBottom: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.handleScroll();
    bindEventListener(window, 'scroll', this.handleScroll);
    bindEventListener(window, 'resize', this.handleScroll);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.animate) {
      this.setState({
        isBottom: false,
      });
    } else {
      // Check again if sticky container is at bottom after Coupon window animation has ended.
      this.timeout = setTimeout(() => {
        if (this.props.step !== PAYMENT_OPTION_FORM_COMBINATION) {
          this.handleScroll();
        }
      }, 350);
    }
  }

  componentDidUpdate(prevProps) {
    const { paymentOptionsToCombine, step } = this.props;
    const { paymentOptionsToCombine: prevPaymentOptionsToCombine, step: prevStep } = prevProps;

    const isStepOrPaymentOptionsChanged = step !== prevStep || paymentOptionsToCombine !== prevPaymentOptionsToCombine;

    const hasSelectedPaymentCombination =
      paymentOptionsToCombine.length > 0 && step === PAYMENT_OPTION_FORM_COMBINATION;

    if (isStepOrPaymentOptionsChanged && hasSelectedPaymentCombination) {
      this.setState({ isBottom: false });
    }
  }

  componentWillUnmount() {
    bindEventListener(window, 'scroll', this.handleScroll);
    bindEventListener(window, 'resize', this.handleScroll);
    clearTimeout(this.timeout);
  }

  handleScroll() {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const { body } = document;
    const html = document.documentElement;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;

    if (this.props.step !== PAYMENT_OPTION_FORM_COMBINATION) {
      this.setState({
        isBottom: windowBottom >= docHeight,
      });
    }
  }

  render() {
    let stickyContainerClass = '';
    if (this.props.hasBackUrl) {
      stickyContainerClass += ' with-back-url';
    }
    if (this.props.hasDescription) {
      stickyContainerClass += ' with-description';
    }

    const isPaymentOptionsToCombineEmpty = this.props.step === PAYMENT_OPTION_FORM_COMBINATION && this.props.paymentOptionsToCombine.length === 0;

    return (
      <div className={`sticky-container-placeholder ${stickyContainerClass}`}>
        <div
          className={classNames('sticky-container', {
            bottom: this.state.isBottom || isPaymentOptionsToCombineEmpty,
            'no-fixed': isPaymentOptionsToCombineEmpty,
          })}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

StickyContainer.defaultValues = {
  children: null,
};

StickyContainer.defaultProps = {
  hasDescription: false,
  hasBackUrl: false,
  step: '',
  paymentOptionsToCombine: [],
  animate: false,
};

StickyContainer.propTypes = {
  hasDescription: PropTypes.boolean,
  hasBackUrl: PropTypes.boolean,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  step: PropTypes.string,
  paymentOptionsToCombine: PropTypes.array,
  animate: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  animate: state.animation.animate.group_animated_overlay_and_expand,
  step: state.page.data.currentStep,
  paymentOptionsToCombine: state.combinations.paymentOptionsToCombine,
});

if (process.env.NODE_ENV === 'test') {
  module.exports = StickyContainer;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(StickyContainer);
}
