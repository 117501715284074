/* global window */

const path = require('path');

const { loginConfig, loginChallengeRemedyConfig } = require('./LoginPopUp');
const { kycConfig, kycSubscriptionsConfig, kycCreditsConfig, kycPayerCardsConfig } = require('./ChallengeKYC');
const { twofactorConfig } = require('./Challenge2FA');
const { challenge3DSConfig } = require('./Challenge3DS');
const { challengeUnicoConfig } = require('./ChallengeUnico');
const { challengeRandomChargeConfig } = require('./ChallengeRandomCharge');
const { challengeFacetecConfig } = require('./ChallengeFacetec');
const { logErrorFromClient } = require('./logTags');
const { ERROR_SPA, CHALLENGES } = require('../../constants/app');

const configs = (challengeName) =>
  ({
    login: loginConfig,
    login_challenge_remedy: loginChallengeRemedyConfig,
    login_irreversible: loginConfig,
    kyc_ifpe: kycConfig,
    emisor_kyc: kycConfig,
    subscriptions_kyc: kycSubscriptionsConfig,
    reauth: twofactorConfig,
    emisor_reauth: twofactorConfig,
    kyc_bacen: kycConfig,
    consumer_credits_kyc: kycCreditsConfig,
    payer_cards_mlm_kyc: kycPayerCardsConfig,
    challenge_3ds_remedy: challenge3DSConfig,
    unico_challenge_remedy: challengeUnicoConfig,
    challenge_random_charge_remedy: challengeRandomChargeConfig,
    challenge_facetec_remedy: challengeFacetecConfig,
  }[challengeName]);

const generateBaseUrlWith = (suffix) => {
  let protocol;
  let host;

  if (typeof window !== 'undefined' && window !== null) {
    protocol = window.location.protocol;
    host = window.location.host;

    return `${protocol}//${path.join(host, suffix)}`;
  }

  return suffix;
};

/**
 * @private
 * Generate our simple waiting page url
 * @param configurations
 * @param redirectUrl
 * @returns {string}
 */
const generateWaitingPage = ({ brandName = '', login = { popup: {} } }) => {
  const basePath = login.popup.base_path;
  return generateBaseUrlWith(`${basePath}?merchant=${brandName}`);
};

/**
 * @private
 * Generate an url with the given path, otherwise return waiting page
 * @param configurations
 * @param callbackPath
 * @returns {string}
 */
const generateCallbackUrl = (configurations, callbackPath) => {
  if (callbackPath && typeof window !== 'undefined') {
    return generateBaseUrlWith(`${configurations.baseName}/${callbackPath}`);
  }

  return generateWaitingPage(configurations);
};

/**
 * @private
 * Extracts challenge's component data from stepData
 * @param {object} stepData
 * @returns {object}
 */
const getChallengeRawData = (stepData) => {
  // eslint-disable-next-line no-useless-catch
  try {
    // Legacy: Apply to login & login_irreversible
    const { currentStep, flowId } = stepData;
    const meliSessionId = stepData.meliSessionId || '';

    if (configs(currentStep)) {
      return {
        challenge: currentStep,
        flowId,
        meliSessionId,
      };
    }

    const challengeData = Array.isArray(stepData?.components)
      ? stepData.components.find((component) => component.type === CHALLENGES.CHALLENGE_COMPONENT_TYPE)
      : {};

    // Challenges
    return {
      ...challengeData,
      flowId,
      meliSessionId,
    };
  } catch (error) {
    logErrorFromClient(error, ERROR_SPA.CHALLENGE_CONFIG_RAW_DATA, '[ChallengeConfigs][getChallengeRawData]', {
      components: stepData.components,
      current_step: stepData.currentStep,
      flow_id: stepData.flowId,
      meli_session_id: stepData.meliSessionId,
      step_data: stepData,
    });

    throw error;
  }
};

/**
 * @public
 * Know if there is a challenge into the give stepData
 * @param {object} stepData
 * @returns {boolean}
 */
const isChallenge = (stepData) => {
  const challengeRawData = getChallengeRawData(stepData);
  const challengeConfigs = configs(challengeRawData.challenge);
  return challengeRawData && !!challengeConfigs;
};

/**
 * @public
 * Returns a promise with the challenge's configuration
 * @param {object} stepData
 * @param {object} configurations
 * @param {string} callbackPath
 * @returns {Promise}
 */
const getConfig = async (stepData, configurations, callbackPath) => {
  const challengeRawData = getChallengeRawData(stepData);
  const builder = challengeRawData && configs(challengeRawData.challenge);

  if (builder) {
    const callbackUrl = generateCallbackUrl(configurations, callbackPath);
    return builder(challengeRawData, configurations, callbackUrl, stepData.components, stepData.hidden_components);
  }

  return null;
};

module.exports.isChallenge = isChallenge;
module.exports.getConfig = getConfig;
module.exports.generateWaitingPage = generateWaitingPage;
module.exports.generateCallbackUrl = generateCallbackUrl;
