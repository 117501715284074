import React from 'react';

const Info = ({
  width = 12,
  height = 12,
  color = '#555',
  ...props
}: SVGProps) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 10 10"
  >
    <path
      fill={color}
      fillOpacity="0.55"
      fillRule="evenodd"
      d="M10 5A5 5 0 110 5a5 5 0 0110 0zM6.535 7.016v.64H3.677v-.64h.942V4.632h-.712v-.64h1.647v3.024h.981zm-.971-4.208a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM.769 5a4.23 4.23 0 108.462 0A4.23 4.23 0 00.769 5z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default React.memo(Info);
