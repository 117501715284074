const React = require('react');
const PropTypes = require('prop-types');

const { connect } = require('react-redux');

const GroupAnimated = (props) => {
  return (
    <div className={`animated-group ${props.animation} ${props.animate ? 'animate' : ''}`}>
      {React.Children.map(props.children, (child) => React.cloneElement(child, { disabled: props.animate }))}
    </div>
  );
};

GroupAnimated.propTypes = {
  animation: PropTypes.oneOf(['overlay_and_expand', 'disappear', 'disabled', '']),
  id: PropTypes.string,
};

GroupAnimated.defaultProps = {
  animation: '',
  id: '',
};

const mapStateToProps = (state, ownProps) => ({
  animate: state.animation.animate[ownProps.id],
});

if (process.env.NODE_ENV === 'test') {
  module.exports = GroupAnimated;
} else {
  /* istanbul ignore next: cant test it with tests */ module.exports = connect(mapStateToProps)(GroupAnimated);
}
