/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');

// Store
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

// Andes Components
const { Title } = require('@andes/typography');
const { Message } = require('@andes/message');

// Cow Components
const Navbar = require('@cow/core-components/components/NavbarV2');
const PaymentSummaryMobile = require('@cow/core-components/components/PaymentSummaryMobile');
const CardList = require('@cow/core-components/components/CardList');
const CouponCollapsible = require('@cow/core-components/components/CouponCollapsible');
const CardMercadoCredits = require('@cow/core-components/components/MercadoCreditsBanner');
const CardSwitch = require('@cow/core-components/components/CardSwitch');
const CaptchaTerms = require('@cow/core-components/components/CaptchaTerms');
const Legals = require('@cow/core-components/components/Legals');
const Brand = require('@cow/core-components/components/Brand');
const GenericModal = require('@cow/core-components/components/GenericModal');
const KycModal = require('@cow/core-components/components/KycModalV2');
const StickyPayButton = require('@cow/core-components/components/StickyPayButton');
const BackLink = require('@cow/core-components/components/BackLink');
const { useNavbar }  = require('../../../../hooks/useNavbar');
const { sanitizeUrl } = require('../../../../utils/UrlUtils');

// Custom Hooks
const { useActions } = require('../hooks/useActions');
const { useCardList } = require('../hooks/useCardList');

// i18n
const injectI18n = require('nordic/i18n/injectI18n');
const translate = require('../../../../translation');

// Internal Components
const Page = require('../../../../components/Page');
const DynamicContent = require('../../../../components/DynamicContent');
const CaptchaV2 = require('../../../../components/CaptchaV2');
const DiscountTrigger = require('../components/DiscountTrigger');

// Constants
const {
  ENVIROMENT,
  COLORS_BY_STATUS,
  CHECKOUT_CASE: { SUBSCRIPTION, GUEST, LOGGED },
  SITE_ID,
  DEVICE_TYPE,
  EXTERNAL_URLS,
  ASSETS,
} = require('../../../../../constants/commons');
const { CAPTCHA_VERSION, SUMMARY_ITEMS_TYPES } = require('../../../../../constants/app');
const { INPUTS_ID, BUTTONS } = require('../../../../../constants/ui');

// Actions
const STEP_ACTIONS = require('../../../../spa/actions/step');
const SNACKBAR_ACTIONS = require('../../../../spa/actions/snackbar');
const ANIMATION_ACTIONS = require('../../../../spa/actions/animations');
const LOADING_ACTIONS = require('../../../../spa/actions/loading');
const REQUEST_ACTIONS = require('../../../../spa/actions/request');
const CAPTCHA_TOKEN_ACTIONS = require('../../../../spa/actions/captchaToken');

// Utils
const {
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
  genericPropsTypes,
  genericDefaultValues,
} = require('../../../../utils/propTypes');
const { createToggleFunctionWithAnimation } = require('../utils/discount');
const {
  handleSummaryItemProps,
  isCollapsible,
  getMobileTotal,
  hasBrand,
  PAYMENT_SUMMARY_ALIGNMENT,
} = require('../../../../utils/summary');
const { buildSubscriptionFreeTrialMessage } = require('../utils/subscription');
const { getCompanyTheme, getBrandNameCompany } = require('../utils/configurations');
const { isMobile } = require('../../../../utils/webview');
const { getMercadoPagoIconBySiteId } = require('../../../../utils/icons');
const PCJ = require('../../../../utils/pcj');

const Review = (originalProps) => {
  const translations = translate(originalProps.i18n);
  const props = {...PCJ.ifCandidateInjectPropsIntoSummary({
    props: originalProps,
    paymentMethod: originalProps.step_model.payment_methods[0],
    translations,
  })};

  // Template custom content props
  const {
    siteId,
    flow,
    history,
    stepActions,
    snackbarActions,
    blockedByCaptcha,
    bellowIncentives,
    customCardList,
    email,
    browserName,
    requestActions,
    loadingActions,
    loadingPayButton,
    requestLoading,
    loadingStopAnimation,
    captchaConfig,
    captchaToken,
    showKycModal = true,
    showIncentives = true,
    customSummaryItems = null,
    consumerCreditsFooter = null,
    customHandlePayClick = null,
    customFooterMessage = null,
    customHandleKyc = null,
    customPrice = null,
    step_model: {
      transaction_type,
      challenge_instructions,
      payer,
      step_title,
      discount,
      summary,
      payment_methods,
      incentives,
      brand,
      terms_and_conditions,
      subscription,
      alternative_payment_method,
      captcha,
      shipping,
      main_action,
      errors,
      navigation,
      notification,
    },
    platform,
    configs
  } = props;

  const NavbarMenuOptions = {
    title: translations.YOU_ENTERED_AS,
    changeUserLabel: translations.CHANGE_ACCOUNT,
    logOutUserLabel: translations.LOG_OUT,
  };

  const templateCase = props.templateCase || 'default';

  // Ref
  const tooltipContainer = React.createRef();

  // States
  const [discountCodeState, setDiscountCodeState] = React.useState('');
  const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = React.useState(errors?.discount?.code ?? '');
  const [showDiscountModal, setShowDiscountModal] = React.useState(false);
  const [isCouponVisible, setIsCouponVisible] = React.useState(false);
  const [useAlternativePaymentMethod, setUseAlternativePaymentMethod] = React.useState(
    alternative_payment_method?.enabled,
  );

  const progressDone = loadingStopAnimation && requestLoading;
  const hasDiscountCodeApplied = summary.items.find((item) => item.type === SUMMARY_ITEMS_TYPES.COUPON_DISCOUNT);
  const summaryItems = customSummaryItems || summary?.items;

  // Actions Hook
  const {
    showNotification,
    changePaymentMethod,
    changeInstallments,
    dismissConsumerCredits,
    payConsumerCredits,
    sentDiscountCode,
    changeExtraData,
    changeShippingOption,
    changeShippingAddress,
    kyc,
    logout,
    pay,
  } = useActions({
    siteId,
    browserName,
    flow,
    history,
    stepActions,
    snackbarActions,
    discountCode: discountCodeState,
    setDiscountCodeErrorMessage,
    payer,
    email,
    challengesInstructions: challenge_instructions,
    captcha,
    requestActions,
    captchaToken,
    captchaConfig,
    translations,
    useAlternativePaymentMethod,
    loadingActions,
    platform,
    configurations: configs
  });

  const handleDiscountCodeInput = (event) => {
    setDiscountCodeState(event.target.value);
    setDiscountCodeErrorMessage('');
  };

  // Card List Hook
  const { buildCardList } = useCardList({
    siteId,
    isBppFlow: incentives?.is_bpp_flow,
    translations,
    isGuest: payer.is_guest,
    payer,
    shipping,
    deviceType: props.deviceType,
    actions: {
      changePaymentMethod,
      changeInstallments,
      changeExtraData,
      changeShippingOption,
      changeShippingAddress,
    },
  });

  const cardListOptions = buildCardList(payment_methods);

  // IDs
  const discountTriggerId = 'discount-btn--trigger';

  // ===== Handle Behaviors ====
  const getElement = () => document.querySelector('.content-row');
  const getVisibleState = () => isCouponVisible;
  const setVisibleState = (state) => setIsCouponVisible(state);

  const toggleDiscount = createToggleFunctionWithAnimation(getElement, getVisibleState, setVisibleState);

  const toggleAlternativePaymentMethod = () => {
    setUseAlternativePaymentMethod((prevState) => !prevState);
  };

  // ===== Payment Summary Mobile =====
  const isPaymentSummaryCollapsible = isCollapsible(summaryItems?.length, transaction_type, summary);

  // Handle Pay Click (submit button)
  const handlePayClick = () => {
    if (customHandlePayClick) {
      customHandlePayClick();
      return;
    }

    pay();
  };

  // Notifications
  React.useEffect(() => {
    if (notification) {
      // TODO IMPROVE THIS BEHAVIOR TO MATCH WITH PRODUCTION
      document.getElementById(BUTTONS.DISCOUNT_TRIGGER)?.click();
      setDiscountCodeState('');

      showNotification({ component: notification.message, displayTimeout: 10000 });
    }
  }, [notification]);

  // Discount Animation
  React.useEffect(() => {
    if (discount?.is_applicable) {
      const contentContainer = document.querySelector('.content');
      const contentRowContainer = document.querySelector('.content-row');

      contentContainer.style.position = 'relative';
      contentRowContainer.classList.add('content-row--animated');

      // Set the correct size for the "content" element when the "content-row" be major
      // This is because the "content-row" is using position: absolute and the "content" element is using position: relative (For the animation purpose)
      if (contentRowContainer.offsetHeight > contentContainer.offsetHeight) {
        contentContainer.style.height = `${contentRowContainer.offsetHeight}px`;
      }
    }
  }, []);

  const theme = getCompanyTheme(configs?.internalConfigurations);
  const { showNavbar } = useNavbar(flow, payer.is_guest, configs?.isWebview);

  return (
    <Page
      title={props.stepTitle}
      currentStep={`${props.currentStep}_template_${templateCase}`}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <div className="layout layout--new-interface">
        {/* Space for Modals */}
        {!!challenge_instructions?.kyc && showKycModal && (
          <KycModal
            dispatcherSelector={`.${BUTTONS.MOBILE_PAY}`}
            description={challenge_instructions.kyc.description}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            title={challenge_instructions.kyc.title}
            image={ASSETS.KYC_IMAGE}
            submitLabel={translations.START}
            alternativeOptionLabel={translations.PAY_WITH_ANOTHER}
            onSubmit={customHandleKyc || kyc}
            onSubmitAlternativeOption={changePaymentMethod}
            className={`kyc-modal--${challenge_instructions.kyc.type}`}
          />
        )}

        {discount?.is_applicable && (
          <GenericModal
            className="discount-modal"
            title={translations.GENERAL_CONDITIONS}
            content={<>{discount?.detail}</>}
            isOpen={showDiscountModal}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            onClose={() => setShowDiscountModal(false)}
            onOpen={() => setShowDiscountModal(true)}
          />
        )}

        {/* Space for Header */}
        {showNavbar && (
          <Navbar
            user={{
              fullName: payer.name,
              firstname: payer.first_name,
              lastname: payer.last_name,
              email: payer.email.value,
              avatar: payer.avatar,
            }}
            menuOptions={NavbarMenuOptions}
            theme={theme}
            isMLB={siteId === SITE_ID.MLB}
            isMobile={isMobile(props.deviceType)}
            logOut={() => logout({ changeUser: false })}
            changeUser={() => logout({ changeUser: true })}
            altLogo={getBrandNameCompany(theme, translations)}
            altClosedMenu={translations.YOU_ARE_USING_YOUR_ACCOUNT(payer.first_name)}
            altOpenedMenu={translations.CLOSE_ACCOUNT_OPTION}
          />
        )}

        {hasBrand(brand) && (
          <Brand
            image={brand.avatar}
            name={brand.name}
            isMobile={isMobile(props.deviceType)}
            isUserLogged={!payer.is_guest}
            withNavbar={showNavbar}
          />
        )}

        <PaymentSummaryMobile
          isUserLogged={!payer.is_guest}
          title={summary?.title}
          isCollapsible={isPaymentSummaryCollapsible}
          items={
            (isPaymentSummaryCollapsible || transaction_type === SUBSCRIPTION) &&
            summaryItems?.map((item) =>
              handleSummaryItemProps({
                item,
                summary,
                translations,
                deviceType: props.deviceType,
                tooltipContainer,
                setShowDiscountModal,
                iconAlignment: isPaymentSummaryCollapsible
                  ? PAYMENT_SUMMARY_ALIGNMENT.LEFT
                  : PAYMENT_SUMMARY_ALIGNMENT.RIGHT,
                isSubscription: transaction_type === SUBSCRIPTION,
              }),
            )
          }
          total={getMobileTotal(summary, transaction_type, subscription, customPrice)}
          brand={hasBrand(brand)}
          autoHeightClassname={transaction_type === SUBSCRIPTION ? 'auto-height' : ''}
          aspirational={buildSubscriptionFreeTrialMessage(subscription)}
          withNavbar={showNavbar}
        />

        <section
          className={`layout__main-content
          ${payer.is_guest ? 'user-guest' : 'user-logged'}
          ${hasBrand(brand) ? 'cow-brand-content' : ''}
          ${isPaymentSummaryCollapsible ? '' : 'is-payment-summary-static'}
        `}
        >
          <div className="top-row">
            <Title component="h3" size="xs">
              {step_title}
            </Title>
            <DiscountTrigger
              isApplicable={discount?.is_applicable}
              labelToggle={translations.I_HAVE_A_DISCOUNT}
              discountTriggerId={discountTriggerId}
              toggleDiscount={toggleDiscount}
            />
          </div>

          <div className="content">
            <div className="content-row">
              <DynamicContent defaultContent={<CardList items={cardListOptions} />} customContent={customCardList} />
              <DynamicContent
                defaultContent={
                  <>
                    {incentives?.credits && showIncentives && (
                      <CardMercadoCredits
                        title={translations.PURCHASE_NOW_AND_PAY_LATER}
                        description={incentives?.credits?.description || ''}
                        promotion={incentives?.credits?.interest_text || ''}
                        submitLabel={translations.PAY_WITH_MERCADO_CREDIT}
                        onClose={dismissConsumerCredits}
                        onClick={payConsumerCredits}
                        isMobile={isMobile(props.deviceType)}
                      />
                    )}

                    {subscription?.validation && Object.keys(subscription?.validation)?.length && (
                      <Message
                        className="message__payment-validation"
                        color={COLORS_BY_STATUS[subscription.validation.status?.toUpperCase()]}
                        hierarchy="quiet"
                        title={subscription.validation.title}
                      >
                        {subscription.validation.label}
                      </Message>
                    )}

                    {/* Combination Card Switch */}
                    {/* TODO: Use i18n for the text of the 'description' prop of the CardSwitch component (Check with the UX team if the wording exists) */}
                    {useAlternativePaymentMethod !== null && useAlternativePaymentMethod !== undefined && (
                      <CardSwitch
                        description="Usar mi dinero disponible en Mercado Pago cuando no sea posible cobrar de la tarjeta"
                        icon={getMercadoPagoIconBySiteId(siteId, SITE_ID.MLA === siteId)}
                        onChange={toggleAlternativePaymentMethod}
                        value={useAlternativePaymentMethod}
                      />
                    )}

                    {/* Captcha */}
                    {captcha?.version === CAPTCHA_VERSION.V2 && (
                      <div
                        className={`payment-summary__bottom-content cow-captcha__container cow-captcha__container--${
                          payer.is_guest ? GUEST : LOGGED
                        }`}
                      >
                        <CaptchaV2
                          {...props}
                          show={captcha?.version === CAPTCHA_VERSION.V2}
                          captchaRetry={captcha?.is_retry || false}
                        />
                      </div>
                    )}
                  </>
                }
                customContent={bellowIncentives}
              />

              {/* Footer messages */}
              <div className="footer-terms">
                {customFooterMessage}

                {consumerCreditsFooter}

                {terms_and_conditions?.has_legals && (
                  <Legals
                    // TODO: Create a Help component to avoid mix the siteId with this Legals component (ANK-2811 & ANK-2812)
                    isMCO={siteId === SITE_ID.MCO}
                    isMobile={isMobile(props.deviceType)}
                    isUserLogged={!payer.is_guest}
                    brand={{
                      processByLabel: translations.PROCESSED_BY,
                      image: ASSETS.MERCADOPAGO_LOGO,
                      imageAlt: 'Mercado Pago',
                    }}
                    terms={{
                      acceptLabel: translations.ACCEPT_THE_TERMS,
                      termAndConditionsLabel: translations.TERMS_AND_CONDITIONS,
                      termsAndConditionsLink: EXTERNAL_URLS.LEGAL_TERMS,
                      brandLabel: translations.OF_MP,
                    }}
                    help={{
                      label: translations.HELP_TO_PROTECT_YOUR_PURCHASES,
                      link: EXTERNAL_URLS.PROTECT_YOUR_PURCHASE,
                    }}
                  />
                )}

                {terms_and_conditions?.has_captcha && (
                  <CaptchaTerms
                    protectedByLabel={translations.PROTECTED_BY_RECAPTCHA}
                    privacyLabel={translations.PRIVACY}
                    privacyLink={EXTERNAL_URLS.CAPTCHA_PRIVACY}
                    conditionsLabel={translations.CONDITIONS}
                    conditionsLink={EXTERNAL_URLS.CAPTCHA_TERMS}
                  />
                )}
              </div>

              {/* Back link */}
              {navigation?.back_urls?.failure?.length > 0 && (
                <div className="footer-terms--backlink-container">
                  <BackLink label={translations.RETURN_TO_SITE} href={sanitizeUrl(navigation.back_urls.failure)} />
                </div>
              )}
            </div>

            {/* Space for notifications */}
            <CouponCollapsible
              value={discountCodeState}
              inputId={INPUTS_ID.DISCOUNT_CODE}
              labelInput={translations.DISCOUNT_CODE}
              description={discountCodeErrorMessage || translations.YOU_CAN_ONLY_USE_ONE_DISCOUNT_CODE_AT_A_TIME}
              submitButtonLabel={translations.APPLY_DISCOUNT}
              style={discountCodeErrorMessage ? 'error' : undefined}
              onClick={sentDiscountCode}
              onChange={handleDiscountCodeInput}
              show={discount?.is_applicable}
              device={DEVICE_TYPE.MOBILE}
            />
          </div>
        </section>
      </div>

      <StickyPayButton
        label={main_action}
        onPayClick={handlePayClick}
        withBackgroundOnHandleScroll
        securityPaymentInformationLabel={incentives?.is_secure_payment ? translations.SECURE_PAYMENT : null}
        payButtonHelperText={subscription?.instructions}
        loadingButton={loadingPayButton && !progressDone}
        disablePayButton={blockedByCaptcha || (isCouponVisible && !hasDiscountCodeApplied)}
      />
    </Page>
  );
};

Review.defaultProps = {
  step_model: {
    ...newInterfaceGenericDefaultValues,
  },
  ...genericDefaultValues,
  // States
  email: '',
  browserName: '',
  showKycModal: true,
  showIncentives: true,
  requestLoading: false,
  blockedByCaptcha: false,
  loadingPayButton: false,
  loadingStopAnimation: false,
  // Elements
  customPrice: undefined,
  customSidebar: undefined,
  customCardList: undefined,
  bellowIncentives: undefined,
  customNotifications: undefined,
  customFooterMessage: undefined,
  consumerCreditsFooter: undefined,
  consumerCreditsSummary: undefined,
  customSummaryItems: undefined,
  // Actions
  customHandlePayClick: undefined,
  customHandleKyc: undefined,
};

Review.propTypes = {
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  ...genericPropsTypes,
  // States
  email: PropTypes.string,
  browserName: PropTypes.string,
  requestLoading: PropTypes.bool,
  loadingPayButton: PropTypes.bool,
  blockedByCaptcha: PropTypes.bool,
  emailErrorMessage: PropTypes.string,
  loadingStopAnimation: PropTypes.bool,
  setEmailErrorMessage: PropTypes.func,
  showIncentives: PropTypes.bool,
  showKycModal: PropTypes.bool,
  // Elements
  footer: PropTypes.node,
  customPrice: PropTypes.node,
  customSidebar: PropTypes.node,
  bellowIncentives: PropTypes.node,
  customFooterMessage: PropTypes.node,
  consumerCreditsFooter: PropTypes.node,
  consumerCreditsSummary: PropTypes.node,
  customSummaryItems: PropTypes.arrayOf(PropTypes.shape({})),
  // Actions
  customHandlePayClick: PropTypes.func,
  customHandleKyc: PropTypes.func,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  animationActions: bindActionCreators(ANIMATION_ACTIONS, dispatch),
  captchaToken: bindActionCreators(CAPTCHA_TOKEN_ACTIONS, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  captchaConfig: state.configurations.captcha,
  browserName: state.configurations.browserName,
  requestLoading: state.request.loading,
  loadingPayButton: state.request.loadingPayButton,
  loadingStopAnimation: state.request.loadingStopAnimation,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  currency: state.configurations.currency,
  platform: state.configurations.platform,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = Review;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(Review));
}
